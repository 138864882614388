import React from 'react';
import sequenceOfOperationsPageReducer, { initialState } from 'reducers/sequenceOfOperationsPageReducer';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import AvailableSequenceOfOperationsPage from 'containers/AvailableSequenceOfOperationsPage';

export default function SequenceOfOperationsApp({ projectId, areAttachmentsAllowed, isReviewing }) {
  const store = createStore(sequenceOfOperationsPageReducer, {
    ...initialState, application: { ...initialState.application, projectId: projectId, areAttachmentsAllowed: areAttachmentsAllowed, isReviewing: isReviewing }
  })

  return (
    <Provider store={store} >
        <AvailableSequenceOfOperationsPage projectId={projectId} />
    </Provider >
  )
}
