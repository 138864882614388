export function collateMessagesAndDispatches(includedData) {
  return includedData.filter(value => value.type == 'message').map((currentValue) => {
    const obj = {}
    const dispatches = includedData.filter(item => item.type == 'dispatch' && item.relationships.message.data.id == currentValue.id)
      obj[currentValue.id] = {message: currentValue, dispatches: dispatches.sort((a, b) => { return a.attributes.email > b.attributes.email ? 1 : -1 } )}
    return obj
  })
}

export function concatToCurrentState(includedData, currentState) {
  const stateDupe = {...currentState.collection}
  const data = collateMessagesAndDispatches(includedData)
  data.forEach(object => {
    const id = Object.keys(object)[0]
    stateDupe[id] = object[id]
  })
  return stateDupe
}
