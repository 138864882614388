import React from 'react';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';

import { collectionsDiffer } from 'components/helpers/objects';
import { resourceIdsAsPositionParams } from 'components/helpers/ordering';
import { genericErrorMessage } from 'components/helpers/errors';

import AvailableProjectTrade from 'containers/AvailableProjectTrade';

const SortableItem = SortableElement(({ containerRef, projectTradeId }) => {
  return <AvailableProjectTrade containerRef={containerRef} projectTradeId={projectTradeId} />
})

const SortableList = SortableContainer(({ containerRef, items, isSorting }) => {
  return (
    <ul className='block-list block-list--border-bottom-none pos-rel'>
      {
        items.map((projectTradeId, index) => {
          return (
            <React.Fragment key={`project-trade-${projectTradeId}`}>
              {isSorting && <div className='row-bar--ghost row-bar--rounded row-bar--small tw-border-blue-100 tw-bg-blue-025'></div>}
              <SortableItem
                containerRef={containerRef}
                projectTradeId={projectTradeId}
                index={index}
              />
            </React.Fragment>
          )
        })
      }
    </ul>
  )
});


const collapsedItemSpace = 80;

export default function ProjectTradeList({ dispatch, projectTradeIds, isSorting, containerRef, addError, projectId }) {
  const handleProjectTradeReorder = ({ oldIndex, newIndex }) => {
    const tradeOrderIds = arrayMove(projectTradeIds, oldIndex, newIndex);
    dispatch({ type: 'FINISH_PROJECT_TRADE_ORDERING', newOrder: tradeOrderIds })
    if (collectionsDiffer(oldIndex, newIndex)) requestProjectTradeReorder(tradeOrderIds)
  };

  const handleBeforeSort = ({ node, index }) => {
    dispatch({ type: 'START_PROJECT_TRADE_ORDERING', options: { containerRef, node, index, collapsedItemSpace } })
  };

  const requestProjectTradeReorder = (tradeOrderIds) => {
    axios
      .post(`/projects/${projectId}/project_trades_bulk_updates`, {
        project_trades: { project_trade_attributes: resourceIdsAsPositionParams(tradeOrderIds) }
      })
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }))
  }

  return (
    <SortableList
      containerRef={containerRef}
      useDragHandle
      lockAxis='y'
      items={projectTradeIds}
      onSortEnd={handleProjectTradeReorder}
      updateBeforeSortStart={handleBeforeSort}
      isSorting={isSorting}
      helperClass={'sortable-item--active'}
      getContainer={() => document.getElementById('main')}
      lockToContainerEdges={true}
    />
  )
}
