import React from 'react';
import PropTypes from 'prop-types';

import { getBookingDate, checkIsDateReached } from 'components/helpers/resources/bookings';

import Tooltip from 'components/application/Tooltip';
import SmallStubPill from 'components/application/pills/SmallStubPill';

export default function RegularCourseBookedPill({ booking }) {
  const bookingDate = getBookingDate(booking);
  const isDateReached = checkIsDateReached(booking);

  return (
    <Tooltip placement='top-end' trigger='hover' tooltip={`Training booked for ${bookingDate && bookingDate.format('D MMM YYYY')}`}>
      <SmallStubPill className={`app-text__x-small t--bold va-middle ${!isDateReached ? 'tw-bg-green-025 tw-text-green-800' : 'tw-bg-cyan-025 tw-text-cyan-800'}`}>
        Booked
      </SmallStubPill>
    </Tooltip>
  )
}

RegularCourseBookedPill.propTypes = {
  booking: PropTypes.object.isRequired
}
