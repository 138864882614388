import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useBreadBoard } from 'components/contexts/Toaster';
import Select, { components } from 'react-select';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { getNextPageParam } from "components/helpers/reactQuery";
import { DropdownIndicator } from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import _ from 'lodash';

const staticOptions = {
  'all_personnel': { value: 'all_personnel', label: 'All personnel' },
  'your_teams': { value: 'your_teams', label: 'Your teams' },
  'your_direct_reports': { value: 'your_direct_reports', label: 'Your direct reports'}
}

const teamOption = (team) => {
  return { value: team.id, label: team.attributes.name }
};

export default function PersonnelTeamSelect({filter, onOptionChange}) {
  const breadBoard = useBreadBoard();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const [searchText, setSearchText] = useState("");
  const [valueMenuOpen, setValueMenuOpen] = useState(false);
  const setSearchTextDebounced = useRef(_.debounce(searchText => setSearchText(searchText), 500)).current;

  const fetchManagedTeamsMetaData = () => axios.get('/teams', { params: { meta_only: true, only_managed_teams: true } });

  const fetchSubordinatesMetaData = () => axios.get('/personnel', { params: { meta_only: true, only_subordinates: true } });

  const teamOptions = (resourceData) => {
    const teamsData = resourceData.pages.map((page) => {
      return page.data.map((team) => teamOption(team))
    }).flat()

    const hasNoManagedTeams = metaData.teamsMetaData.scopedCount === 0
    const hasNoSubordinates = metaData.subordinatesMetaData.scopedCount === 0
    const lineManagerViewDisabled = !trainingRegisterResourceManagementContext.hasSubordinateManagementFeatureAccess

    let reportsDisabledReason;
    if (lineManagerViewDisabled) {
      reportsDisabledReason = 'This view can be enabled by upgrading your plan'
    } else if (hasNoSubordinates) {
      reportsDisabledReason = "You aren't the line manager for any personnel"
    }

    const additionalOptions = [ {
      options: [
        staticOptions['all_personnel'],
        { ...staticOptions['your_teams'], isDisabled: hasNoManagedTeams, disabledReason: "You don't manage any teams" },
        { ...staticOptions['your_direct_reports'], isDisabled: reportsDisabledReason, disabledReason: reportsDisabledReason }
      ]
    }
    ];

    return additionalOptions.concat({ options: teamsData, label: 'Teams' });
  }

  const teamsParams = { search: searchText };
  const fetchTeams = (page) => axios.get('/teams', { params: { ...teamsParams, page: page } });

  const {
    data: teams,
    isFetching: teamsIsFetching,
    fetchNextPage: teamsFetchNextPage,
    hasNextPage: teamsHasNextPage
  } = useInfiniteQuery({
    queryKey: ['teams', teamsParams],
    queryFn: async ({ pageParam = 1 }) => {
      const teamsResponse = await fetchTeams(pageParam);
      return teamsResponse.data
    },
    getNextPageParam: getNextPageParam,
    enabled: valueMenuOpen || !!searchText,
    onError: breadBoard.addInedibleToast
  })

  const {
    data: metaData
  } = useQuery({
    queryKey: ['personnelTeamsSelectMetaData'],
    queryFn: async () => {
      const [teamsMetaDataResponse, subordinatesMetaResponse] = await Promise.all([fetchManagedTeamsMetaData(), fetchSubordinatesMetaData()])
      return { teamsMetaData: teamsMetaDataResponse.data.meta, subordinatesMetaData: subordinatesMetaResponse.data.meta }
    },
    enabled: valueMenuOpen,
    onError: breadBoard.addInedibleToast
  })

  const handleMenuOpen = () => {
    setValueMenuOpen(true)
  }

  const handleMenuClose = () => {
    setValueMenuOpen(false)
  }

  const determineValue = () => {
    if (valueMenuOpen) {
      return '';
    }

    return staticOptions[filter.value] || { value: filter.value, label: filter.label }
  };

  const handleChange = (selectedOption) => {
    onOptionChange(selectedOption)
  }

  return (
    <Select
      className='collection-select__select-container collection-select__select-container--teams'
      classNamePrefix='collection-select'
      name='teamNames'
      id='teamNames'
      components={{ DropdownIndicator, Option}}
      value={determineValue()}
      placeholder='Search teams...'
      onChange={handleChange}
      OptionDisabled={option => option.isDisabled}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      options={teams && metaData ? teamOptions(teams) : []}
      isLoading={teamsIsFetching}
      loadingMessage={() => null}
      noOptionsMessage={() => !!searchText ? 'No matching results' : null}
      onInputChange={setSearchTextDebounced}
      onMenuScrollToBottom={() => { if (teamsHasNextPage) teamsFetchNextPage() }}
      openMenuOnFocus={true}
  />
  )
}

export const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={props.data.isDisabled ? 'tooltip-parent' : ''}>
        <div className='collection-select__option_container tw-truncate'>
          <span>{props.children}</span>
        </div>
        {props.data.isDisabled && <Tooltip tooltip={props.data.disabledReason} placement='right' trigger='hover' className="tw-ml-6" arrow />}
      </div>
    </components.Option>
  )
};

PersonnelTeamSelect.propTypes = {
  filter: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  onOptionChange: PropTypes.func.isRequired
};
