import React, { useState } from 'react';
import ProjectActivityTable from 'components/projectActivity/ProjectActivityTable';
import ProjectActivityBlankNotice from 'components/projectActivity/ProjectActivityBlankNotice';
import { concatToCurrentState } from 'components/helpers/resources/messages';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';

export default function ProjectActivityTab(props) {
  const {
    projectId,
    editProject,
    projectIncomplete,
    downloadDisabled,
    isVersionPdfsEnabled,
    currentUser
  } = props

  const [projectHistories, setProjectHistories] = useState({collection:[], loaded: false, hasMore: true})
  const [messageInfo, setMessageInfo] = useState({collection: {}, loaded: false})
  const [toasts, , , , addInedibleToast] = useToasts();
  const [pageNum, setPageNum] = useState(0)
  const [visibilitySensorIsActive, setVisibilitySensorIsActive] = useState(true)

  const getProjectHistories = () => {
    axios
      .get(`/projects/${projectId}/histories`, { params: {page_no: pageNum + 1} })
      .then(response => {
        setMessageInfo({collection: concatToCurrentState(response.data.included, messageInfo), loaded: true})
        setProjectHistories({collection: projectHistories.collection.concat(convertToObjects(response.data.data, response.data.included)), loaded: true, hasMore: !response.data.meta['is_last_page']})
        setPageNum(parseInt(response.data.meta['loaded_page_no']))
        setVisibilitySensorIsActive(true)
      })
      .catch(_error => addInedibleToast())
  }

  const getSubsequentPages = () => {
    setVisibilitySensorIsActive(false)
    getProjectHistories()
  }

  const resetProjectHistoriesState = () => {
    setPageNum(0)
    setProjectHistories({collection:[], loaded: false, hasMore: true})
  };

  const convertToObjects = (histories, _includedResources) => {
    return histories.map((history) => {
      return {
        id: history.id,
        reason: history.attributes.reason,
        createdAt: history.attributes.createdAt,
        displayNumber: history.attributes.displayNumber,
        nadminUser: history.attributes.nadminUserShortName,
        messageId: history.relationships.message.data && history.relationships.message.data.id,
        versionId: history.relationships.version.data && parseInt(history.relationships.version.data.id)
      }
    })
  }

  return (
    <React.Fragment>
      {projectIncomplete ?
        <ProjectActivityBlankNotice editProject={editProject} isVersionPdfsEnabled={isVersionPdfsEnabled} />
        :
        <ProjectActivityTable
          projectId={projectId}
          currentUser={currentUser}
          isActive={visibilitySensorIsActive}
          projectHistories={projectHistories.collection}
          messageInfo={messageInfo}
          loadMore={getSubsequentPages}
          resetProjectHistoriesState={resetProjectHistoriesState}
          hasMore={projectHistories.hasMore}
          isVersionPdfsEnabled={isVersionPdfsEnabled}
          downloadDisabled={downloadDisabled}
          addInedibleToast={addInedibleToast}
        />
      }
      <ToastRack
        toasts={toasts}
      />
    </React.Fragment>
  )
}
