const handsColours = {
  red: { class: 'hands-red', hex: '#ff0000', rgb: 'rgb(255, 0, 0)' },
  green: { class: 'hands-green', hex: '#008000', rgb: 'rgb(0, 128, 0)' }
};

function setHandsColour(editor, colour) {
  editor.dom.setAttrib(editor.dom.select(`.${colour.class}`), 'data-mce-style', `color: ${colour.hex};`)
  editor.dom.setAttrib(editor.dom.select(`.${colour.class}`), 'style', `color: ${colour.rgb};`)
}

function setHandsColours(editor) {
  setHandsColour(editor, handsColours.red)
  setHandsColour(editor, handsColours.green)
}

function resetHandsColour(editor, colour) {
  editor.dom.removeClass(editor.dom.select(`.${colour.class}`), colour.class)
  editor.dom.setAttrib(editor.dom.select(`span[data-mce-style="color: ${colour.hex};"]`), 'class', colour.class)
}

function resetHandsColours(editor) {
  resetHandsColour(editor, handsColours.red)
  resetHandsColour(editor, handsColours.green)
}

function addHandsColours(editor) {
  setHandsColours(editor)
  editor.on('execCommand', function(e) { if (e.ui === 'forecolor') resetHandsColours(editor) })
  editor.on('setContent', function(_e) { setHandsColours(editor) })
}

function removeToxMenus() {
  const toxMenus =  document.querySelector('.tox-menu');

  if (toxMenus) toxMenus.remove()
}

function addScrollingEvents(editor) {
  editor.on('Wheel', function(_e) { removeToxMenus() })
  document.querySelector('#main').addEventListener('scroll', function(_e) { removeToxMenus() })
}

export {
  handsColours,
  addHandsColours,
  addScrollingEvents
}
