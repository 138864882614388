import HandshqApp from '../handshq-app';

HandshqApp.TasksContainer = function(parent) {
  if (this instanceof HandshqApp.TasksContainer) {
    const _this = this;

    this.parent = parent
    this.dragHandle = _this.parent.root.find('.category-task__drag-handle');
    this.sortable = _this.parent.root.find('.sortable-category-task');
    this.actions = _this.parent.root.find('.category-task__actions');
    this.new = _this.parent.root.find('.category-task__new');
    this.footer = _this.parent.root.find('.category-tasks-footer');
    this.taskName = _this.parent.root.find('.category-task__name');
    this.footerNew = _this.parent.root.find('.category-tasks-footer__new-task');

    this.setup = function() {
      taskDragHandle(_this.dragHandle);
      taskSortable(_this.sortable);

      HandshqApp.categoryFormHelper.setupActions({ resource: 'category-task', container: _this });
      HandshqApp.editableTextareaFactory.customTextAreas(_this.footerNew, setupAddTaskTextArea);
      HandshqApp.domHelper.hoverToggleEvent('.category-task', '.category-task__drag-handle img');
      HandshqApp.editableTextareaFactory.regularTextAreas(_this.taskName);

      return this;
    };

    function taskDragHandle(dragHandle) {
      dragHandle
        .click(function(event) {
          event.stopPropagation();
        })
        .mousedown(function(_event) {
          $(this).closest('tbody').find('.category-task__body').hide();
        });
    }

    function taskSortable(sortable) {
      sortable.on('sortabledropped', function(_event) {
        $(this).find('.category-task__body').show();
      });
    }

    function setupAddTaskTextArea() {
      new HandshqApp.EditableTextarea({
        node: this,
        successCallback: addTaskSuccessCallback,
        cancelCallback: addTaskCancelCallback
      }).setup();
    }

    function addTaskSuccessCallback(editableTextarea, response) {
      const taskCollection = editableTextarea.node.closest('.category-tasks');
      const task = $(response['data']['attributes']['htmlContent']);
      const deleteModal = $(response['data']['attributes']['htmlDelete']);

      task.insertBefore(taskCollection.find('tbody.category-tasks__end'));
      task.find('.category-task').trigger('click')
      deleteModal.insertBefore(taskCollection.find('table.category-tasks__end'));

      new HandshqApp.SortableRecords(task.find('.sortable-records')).setup();

      taskDragHandle(task.find('.category-task__drag-handle'));
      taskSortable(task.closest('.sortable-category-task'));

      HandshqApp.categoryFormHelper.setupDeleteModal({ resource: 'category-task', contentNode: task, modalNode: deleteModal });
      HandshqApp.editableTextareaFactory.regularTextArea.call(task.find('.category-task__name .editable-textarea'));
      HandshqApp.domHelper.hoverToggleEvent(task.find('.category-task'), task.find('.category-task__drag-handle img'));

      _this.parent.subTasksContainer.subTaskActions(task.find('.task-sub-task__actions'), task.find('.task-sub-task__new'));
      _this.parent.subTasksContainer.setupSubTaskTextareas(task);

      HandshqApp.domHelper.toggleElements(_this.footer, _this.new);

      editableTextarea.reset();
    }

    function addTaskCancelCallback(_editableTextarea) {
      HandshqApp.domHelper.toggleElements(_this.footer, _this.new);
    }
  } else {
    throw new Error('HandshqApp.TasksContainer invoked without new');
  }
};
