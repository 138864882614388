import React, { useState, useEffect } from 'react';
import DocumentGeneratorService from 'components/helpers/DocumentGeneratorService';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DownloadIcon from '-!svg-react-loader?name=DownloadIcon!icons/ic-download.svg';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import CheckboxField from 'components/application/CheckboxField';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ErrorIcon from '-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg';
import Polling from 'handshq-app/utilities/polling';

export default function PersonnelTrainingDownload(props) {

  let generator

  const generateUrl = `/personnel/${props.personnelId}/training_report_pdfs`;
  const evidenceStatusUrl = `/personnel/${props.personnelId}/training_evidence_status`;
  const defaultReportOptions = {notes: false, evidence: false, history: false}

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [reportIsGenerating, setReportIsGenerating] = useState(false)
  const [reportOptions, setReportOptions] = useState(defaultReportOptions)
  const [reportHasErrored, setReportHasErrored] = useState(false)

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => {
    setModalIsOpen(false)
    if (!reportIsGenerating || reportHasErrored) {
      reset()
    }
  }

  const reset = () => {
    setReportIsGenerating(false)
    setReportHasErrored(false)
    setReportOptions(defaultReportOptions)
  }

  useEffect(() => {
		if (!props.trainingEvidenceProcessed) {
			evidenceStatusPoller.start()
		}
		return () => { if (evidenceStatusPoller) { evidenceStatusPoller.end() } }
  }, [props.trainingEvidenceProcessed])

  const evidenceStatusPoller = new Polling({
		interval: 1000,
		url: evidenceStatusUrl,
		responseCheck: function(response) { return response.data.processed },
		successCallback: () => { props.onTrainingEvidenceProcessed() },
		failureCallback: () => { console.error('could not load attachments status') }
	});

  const handleChange = (event) => {
    const option = event.target.value
    setReportOptions({...reportOptions, [option]: event.target.checked})
  }

  const checkboxProps = {
    height: 'short',
    onChange: handleChange,
    className: 'action-modal__form-checkbox'
  }

  const handleDownload = () => {
    generateReport(reportOptions)
    setReportIsGenerating(true)
  }

  const generateReport = (options) => {
    const generateParams = {...options, personnel_id: props.personnelId, company_role_id: props.roleId}

    generator = new DocumentGeneratorService({ generateUrl, generateParams, successCallback, failureCallback })
    generator.start()
  }

  const successCallback = (docUrl) => {
    closeModal()
    window.location.href = docUrl;
  };

  const failureCallback = () => {
    setReportHasErrored(true)
  }

  const formContent = (
    <div className='action-modal__box action-modal__box--tall tw-bg-white'>
      <section className='action-modal__header truncated-text-container tw-text-grey-900 tw-text-l tw-font-semibold tw-border-grey-100'>
        Download Training Report
      </section>
      <section className='action-modal__form-body action-modal__form-body--tall tw-border-grey-100'>
        Select any additional content you want in the report:
        <CheckboxField {...checkboxProps} label='Include notes' name='notes' value='notes' checked={reportOptions.notes} labelTextProps={{ className: 'tw-font-medium' }} />
        <CheckboxField {...checkboxProps} label='Include evidence' name='evidence' value='evidence' checked={reportOptions.evidence} labelTextProps={{ className: 'tw-font-medium' }} />
        <CheckboxField {...checkboxProps} labelProps={{className: 'hhq-checkbox__label--w-auto'}} tooltip='All previously recorded training is included for each course. Evidence is only included on the most recent training.' label='Include training history' name='history' value='history' checked={reportOptions.history} labelTextProps={{ className: 'tw-font-medium' }} />
      </section>
      <section className='action-modal__form-footer'>
        <TextButton size='md' color='blue' className='m-r-12' onClick={closeModal}>
          Cancel
        </TextButton>
        <FilledButton color='mint' onClick={handleDownload}>
          Download now
        </FilledButton>
      </section>
    </div>
  )

  return (
    <>
      <OutlinedButton size='sm' color='grey' disabled={props.disabled} onClick={openModal}>
        <DownloadIcon className='m-l--8' width={24} height={24}/>
        <span>Download training report</span>
      </OutlinedButton>
      {modalIsOpen && (
        <ActionModal isOpen={modalIsOpen} closeModal={closeModal} closeable={true}>
          {reportIsGenerating ? (
            <ActionModalContent
              closeModal={closeModal}
              closeable={true}
              headerText='Download Training Report'
              bodyIcon={reportHasErrored ? <ErrorIcon width={48} height={48} className='[&_path]:tw-fill-red-600' /> : <LoadingIcon width={48} height={48}/>}
              bodyTitle={reportHasErrored ? 'Report not generated' : 'Generating report'}
              bodyText={reportHasErrored ? 'Please try again or contact support for help' : 'This may take a few minutes. When it is finished, your project will automatically download so you can close this modal while you wait.'}
              iconStyling='tw-apply-loading-spinner--blue-light'
            />
          ) : formContent}
        </ActionModal>
      )}
    </>
  )
}
