import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';

const TextField = ({
  fieldError,
  isDisabled,
  modifierClassName,
  customMargin,
  withLabel,
  isRequired,
  name,
  label,
  tooltip,
  value,
  onChange,
  removeErrorStyling,
  autoFocus,
  placeholder,
  onKeyDown,
  maxLength,
  min,
  clearable,
  onClear,
  type,
  modifier,
  inputRef
}) => {
  const isInvalid = !!fieldError?.fieldHighlighted;

  const inputClassName = (() => {
    switch (true) {
      case (isDisabled):
        return 'tw-bg-grey-025 tw-text-grey-400 tw-border-grey-200 hover:tw-border-grey-200 focus-within:tw-border-grey-200 hover:focus-within:tw-border-grey-200';
      case (isInvalid):
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  return (
    <div className={`text-field${modifierClassName ? ` field--${modifierClassName}`: ''}`}>
      <div className={`form-group${customMargin ? ` ${customMargin}` : ''}`}>
        {
          withLabel && (
            <label className={`field__label tw-font-medium${isRequired ? " after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal" : ''}${isInvalid ? " after:tw-text-red-600" : ''}`} htmlFor={name}>
              {label}
              {tooltip &&
                <Tooltip placement='top' trigger='hover' tooltip={tooltip}>
                  <CircleQuestion />
                </Tooltip>
              }
            </label>
          )
        }
        <div className={'flex flex--vertically-centered field__input-container pos-rel'}>
          <input
            ref={inputRef}
            id={voca.snakeCase(name)}
            className={`field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${inputClassName}${modifier ? ` field__input--${modifier}` : ''}`}
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            disabled={isDisabled}
            onFocus={removeErrorStyling}
            autoFocus={autoFocus}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            min={min}
          />
          {clearable &&
            <CloseIcon onClick={onClear} className='field__clear [&_polygon]:tw-fill-grey-700' width={24} height={24} />
          }
        </div>
      </div>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

TextField.defaultProps = {
  type: 'text',
  isRequired: false,
  isDisabled: false,
  removeErrorStyling: () => {},
  onKeyDown: () => void(0),
  placeholder: '',
  withLabel: true,
  maxLength: 'unset'
}

export default TextField;
