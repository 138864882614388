import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'components/application/ActionModal';
import TextField from 'components/application/TextField';
import ActionButton from 'components/buttons/ActionButton';
import TextButton from 'components/application/buttons/TextButton';
import ErrorMessage from 'components/application/ErrorMessage';

export default function DuplicationRequestedModal(props) {
  const {
    originalProjectName,
    duplicateProject,
    ramsSingularName,
    isOpen,
    closeModal,
    onChange,
    onDuplication,
    fieldError,
    removeErrorStyling
  } = props

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        closeModal={closeModal}
        closeable={true}
      >
        <div className='action-modal__box tw-bg-white'>
          <section className='action-modal__header truncated-text-container tw-text-grey-900 tw-text-l tw-font-semibold tw-border-grey-100'>
            {`Duplicate ${originalProjectName}`}
          </section>
          <section className='action-modal__form-body tw-border-grey-100'>
            <div className='m-b-16'>
              {`Give the new ${ramsSingularName} a name to create a duplicate.`}
            </div>
            { fieldError.error.detail && <ErrorMessage validationErrors={{projectName: fieldError}} isFallback={false} containerClassnames={''}/> }
            <TextField
              label={`Name of new ${ramsSingularName}`}
              name='projectName'
              value={duplicateProject.projectName}
              isRequired={true}
              onChange={onChange}
              autoFocus={true}
              fieldError={fieldError}
              removeErrorStyling={removeErrorStyling}
              modifier='textfield-padded'
            />
          </section>
          <section className='action-modal__form-footer'>
            <>
              <TextButton size='md' color='blue' className='m-r-12' onClick={closeModal}>
                Cancel
              </TextButton>
              <ActionButton
                styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-light confirm_project_duplicate'
                onClick={onDuplication}
                buttonText={`Duplicate ${ramsSingularName}`}
                disabled={fieldError.fieldHighlighted}
              />
            </>
          </section>
        </div>
      </ActionModal>
    </>
  )
}

DuplicationRequestedModal.defaultProps = {
  ramsSingularName: 'project'
};

DuplicationRequestedModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  duplicateProject: PropTypes.object.isRequired,
  ramsSingularName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDuplication: PropTypes.func.isRequired,
  fieldError: PropTypes.object,
  removeErrorStyling: PropTypes.func
};
