import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { groupAttachmentsByAttachableId } from 'components/helpers/resources/attachments';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import useToggle from 'components/hooks/useToggle';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';

import TrainingRow from 'components/training/TrainingRow';
import TrainingStatus from 'components/training/TrainingStatus';
import TrainingBookings from 'components/training/TrainingBookings';
import ELearningBookings from 'components/training/ELearningBookings';
import TrainingOptions from 'components/training/TrainingOptions';
import MinimalTrainingOptions from 'components/training/MinimalTrainingOptions';

export default function CourseTraining(props) {
  const currentActor = useCurrentActor();
  const [isHistoricalTrainingVisible, toggleHistoricalTraining] = useToggle(false);
  const trackedPersonnelContext = useTrackedPersonnelContext();

  const isArchived = !!props.currentPersonnel.archivedAt;

  const isReadOnlyOrArchived = !props.hasTrainingEditableAccess || isArchived;
  const isBookingAllowedWithinTrackedPersonnelLimits = !trackedPersonnelContext.hasReachedLimit || trackedPersonnelContext.isCurrentPersonnelTracked;

  const attachmentsByAttachableId = groupAttachmentsByAttachableId({ attachments: props.attachments });
  const courseHasELearningCourse = currentActor.isAllowedFeature('e_learning') && !!props.eLearningCourse;
  const isELearningNonCustom = courseHasELearningCourse && !props.eLearningCourse.attributes.custom;

  const renderStatus = ({ currentTrainingStatus }) => {
    return (
      <TrainingStatus
        currentTrainingStatus={isArchived ? 'archived' : currentTrainingStatus}
        isRequired={props.isRequired}
        requiredSource={props.requiredSource}
      />
    )
  };

  const renderBookings = ({ currentTrainingStatus }) => {
    if (isArchived) return null

    const isBookingRequired = (
      (props.isRequired || props.isRequiredByAnything) && isBookingAllowedWithinTrackedPersonnelLimits && props.hasTrainingEditableAccess &&
      !props.currentPersonnel.company.subcontractorId &&
      !['valid', 'expiryDateMissing'].includes(currentTrainingStatus)
    );

    return courseHasELearningCourse ? (
      <ELearningBookings
        booking={props.booking}
        course={props.course}
        eLearningCourse={props.eLearningCourse}
        registration={props.registration}
        currentTrainingStatus={currentTrainingStatus}
        currentPersonnel={props.currentPersonnel}
        isCreditInfoUnavailable={isELearningNonCustom && props.creditInfoUnavailable}
        isBookingRequired={isBookingRequired}
        onAddELearningBooking={props.onAddELearningBooking}
      />
    ) : (
      <TrainingBookings
        booking={props.booking}
        course={props.course}
        currentTrainingStatus={currentTrainingStatus}
        isBookingRequired={isBookingRequired}
        onAddBooking={props.onAddBooking}
        onShowBooking={props.onShowBooking}
      />
    )
  };

  const renderRegularTrainingActions = ({ training }) => {
    if (isReadOnlyOrArchived && props.training.length <= 1) return null

    return (
      <TrainingOptions
        showHistoricalTrainingOnly={isReadOnlyOrArchived}
        isRoleRequired={props.isRoleRequired}
        isRequiredByAnotherRole={props.isRequiredByAnotherRole}
        isRequiredStatus={props.isRequired}
        isAdditional={props.isAdditional}
        isMultipleTraining={props.training.length > 1}
        isHistoricalTrainingVisible={isHistoricalTrainingVisible}
        courseHasELearningCourse={courseHasELearningCourse}
        personnelId={props.personnelId}
        currentPersonnel={props.currentPersonnel}
        training={training}
        course={props.course}
        eLearningCourse={props.eLearningCourse}
        booking={props.booking}
        registration={props.registration}
        userCourse={props.userCourse}
        updateUserCourse={props.updateUserCourse}
        createUserCourse={props.createUserCourse}
        creditInfoUnavailable={isELearningNonCustom && props.creditInfoUnavailable}
        toggleHistoricalTraining={toggleHistoricalTraining}
        onNewTrainingClick={props.onNewTrainingClick}
        onEditTrainingClick={props.onEditTrainingClick}
        onDeleteTrainingClick={props.onDeleteTrainingClick}
        onDeleteCourseClick={props.onDeleteCourseClick}
        onAddBooking={props.onAddBooking}
        onAddELearningBooking={props.onAddELearningBooking}
        onRemoveBooking={props.onRemoveBooking}
        onBookingReminder={props.onBookingReminder}
      />
    )
  };

  const renderHistoricalTrainingActions = ({ training }) => {
    if (isReadOnlyOrArchived) return null
    return (
      <MinimalTrainingOptions
        onEditTrainingClick={() => props.onEditTrainingClick({ training, course: props.course })}
        onDeleteTrainingClick={() => props.onDeleteTrainingClick({ training, course: props.course })}
      />
    )
  };

  useEffect(() => {
    if (props.training.length <= 1 && isHistoricalTrainingVisible) toggleHistoricalTraining(false)
  }, [JSON.stringify(props.training)])

  return (
    <Fragment>
      <TrainingRow
        isArchived={isArchived}
        training={props.training[0]}
        attachments={props.training[0] && attachmentsByAttachableId[props.training[0].id]}
        course={props.course}
        isRequired={props.isRequired}
        setModalBoxData={props.setModalBoxData}
        hasTrainingEditableAccess={props.hasTrainingEditableAccess}
        renderStatus={renderStatus}
        renderBookings={renderBookings}
        renderActions={renderRegularTrainingActions}
      />
      {isHistoricalTrainingVisible && props.training.length > 1 && props.training.slice(1).map(historicalTraining => {
        return (
          <TrainingRow
            key={`historicalTraining--${historicalTraining.id}`}
            isHistorical={true}
            isArchived={isArchived}
            training={historicalTraining}
            attachments={attachmentsByAttachableId[historicalTraining.id]}
            course={props.course}
            isRequired={props.isRequired}
            setModalBoxData={props.setModalBoxData}
            renderActions={renderHistoricalTrainingActions}
          />
        )
      })}
    </Fragment>
  )
}

CourseTraining.propTypes = {
  isRequired: PropTypes.bool,
  isRoleRequired: PropTypes.bool,
  course: PropTypes.object.isRequired,
  eLearningCourse: PropTypes.object,
  training: PropTypes.array,
  booking: PropTypes.object,
  registration: PropTypes.object,
  attachments: PropTypes.array,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminder: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired
}

CourseTraining.defaultProps = {
  hasTrainingEditableAccess: true,
  isRequired: true,
  isRoleRequired: true,
  attachments: [],
  training: [],
  booking: null,
  registration: null
}
