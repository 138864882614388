import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import pluralize from 'pluralize';

import { useCurrentActor } from 'components/contexts/CurrentActor';

import { learningMethodMap } from 'components/helpers/resources/courses';
import { displayCurrencyString, currencyNumberToCurrencyString } from 'components/helpers/currency';

import PersonalInfo from 'components/application/PersonalInfo';
import LogoIcon from '-!svg-react-loader?name=LogoIcon!icons/ihasco-logo-mark.svg';

export default function CourseSidePanelDetailsTab({ course, eLearningCourse }) {
  const currentActor = useCurrentActor();

  const currencyCode = currentActor.division.attributes.currencyCode;

  const renewalFrequency = course.expires ? voca.isBlank(course.renewalFrequency) ? "" : `${pluralize(course.renewalInterval, parseInt(course.renewalFrequency), true)}` : 'Does not expire';
  const evidence = course.requiresEvidence ? 'Required' : 'Not Required'
  const method = learningMethodMap[course.learningMethod];

  const eLearningCourseDuration = eLearningCourse && eLearningCourse.attributes.duration;
  const courseDuration = voca.isBlank(course.timeAmount) ? "" : `${pluralize(course.timeInterval, parseInt(course.timeAmount), true)}`;
  const timeEstimate = eLearningCourse ? eLearningCourseDuration : courseDuration;

  const isELearningCustom = eLearningCourse && eLearningCourse.attributes.custom;
  const customELearningCourseCost = isELearningCustom && currencyNumberToCurrencyString({ number: eLearningCourse.attributes.cost, currencyCode })
  const eLearningCourseCost = isELearningCustom ? displayCurrencyString({ string: customELearningCourseCost, currencyCode }) : '1 credit';
  const courseCostEstimate = displayCurrencyString({ string: course.costEstimate, currencyCode });
  const costEstimate = eLearningCourse ? eLearningCourseCost : courseCostEstimate;

  return (
    <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
      {currentActor.isAllowedFeature('e_learning') && course.isELearningAvailable && (
        <div className='flex content-box fh-64 p-12 m-b-24 tw-border-grey-100 tw-bg-white'>
          <div className='m-r-8'><LogoIcon width={20} height={20} /></div>
          <div>
            <div className='tw-font-medium'>eLearning available</div>
            <div>Edit the course to activate eLearning</div>
          </div>
        </div>
      )}
      <PersonalInfo label='Renewal frequency' text={renewalFrequency} />
      <PersonalInfo label='Evidence' text={evidence} />
      <PersonalInfo label='Method' text={method} />
      <PersonalInfo label='Provider' text={course.provider} />
      <PersonalInfo label='Cost estimate' text={costEstimate} />
      <PersonalInfo label='Time estimate' text={timeEstimate} />
      {!eLearningCourse && <PersonalInfo label='Default booking notes' text={course.defaultBookingNote} isTruncated={false} />}
    </div>
  )
}

CourseSidePanelDetailsTab.propTypes = {
  course: PropTypes.object.isRequired,
  eLearningCourse: PropTypes.object
}
