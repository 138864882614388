import React from 'react';
import Toggleable from 'components/application/Toggleable';
import SubmittableField from 'components/application/SubmittableField';

export default function ToggleableField(props) {
  return (
    <Toggleable
      isToggled={props.isToggled}
      setIsToggled={props.onToggle}
      primary={props.toggleableItem}
      secondary={
        <SubmittableField
          submitButtonText={props.buttonText}
          onCancel={props.onCancel}
          onSubmit={(e, fieldValue) => props.onSubmit(e, fieldValue)}
          value={props.value}
          submitIsDisabled={props.submitIsDisabled}
          onChange={props.onChange}
          autoFocus={props.autoFocus}
          submitFieldPlaceholderText={props.submitFieldPlaceholderText}
        />

      }
    />
  )
}

ToggleableField.defaultProps = {
  showHorizontalRule: false
}
