import HandshqApp from '../handshq-app';

const textFieldInvalidClass = 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';

HandshqApp.EditableTextarea = function(options) {
  if (this instanceof HandshqApp.EditableTextarea) {
    const _this = this;
    let autosave;
    let clearFlag;

    this.node = $(options['node']);
    this.attribute = _this.node.attr('data-attribute');
    this.wysiwyg = _this.node.attr('data-wysiwyg');
    this.wysiwygFlag = $(_this.node.attr('data-wysiwyg-flag-selector'));
    this.wysiwygAutosave = _this.node.attr('data-wysiwyg-autosave');
    this.coloursAllowed = _this.node.attr('data-colours-allowed');
    this.wysiwygEditor = undefined;
    this.wysiwygActions = _this.node.find('.editable-textarea__wysiwyg-actions');
    this.spinner = _this.node.find('.editable-textarea__wysiwyg-spinner');
    this.textWrapper = _this.node.find('.editable-textarea__text-wrapper');
    this.textEditor = _this.node.find('.editable-textarea__text-editor');
    this.textField = _this.node.find("textarea, input[type='text']").eq(0);
    this.cancelButton = _this.node.find('.editable-textarea__cancel');
    this.submitButton = _this.node.find('.editable-textarea__submit');
    this.regularCallbacks = options['regularCallbacks'];
    this.successCallback = options['successCallback'];
    this.failureCallback = options['failureCallback'];
    this.cancelCallback = options['cancelCallback'];

    this.setup = function() {
      _this.textChangeEvent()
      _this.triggerTextFieldValueCheck()
      _this.textWrapperClick()
      _this.cancelClick()
      _this.submitForm()

      HandshqApp.domHelper.clickStopPropagation(_this.textEditor)

      if (_this.wysiwyg) {
        HandshqApp.setupWysiwyg({
          selector: '#' + _this.textField.attr('id'),
          coloursAllowed: _this.coloursAllowed,
          setupCallback: wysiwygSetupCallback.bind(this),
          initInstanceCallback: wysiwygInitInstanceCallback.bind(this)
        })
      }
    };

    this.textChangeEvent = function() {
      if (validatableTextField()) { _this.textField.on('change paste keyup', function() { _this.textFieldValueCheck(this) }) }
    }

    this.triggerTextFieldValueCheck = function() {
      if (validatableTextField()) { _this.textFieldValueCheck(_this.textField[0]) }
    }

    this.textFieldValueCheck = function(field) {
      setTimeout(function () {
        _this.submitButton.attr('data-disabled', field.value.length < 1)
      }, 100)
    }

    this.textWrapperClick = function() {
      _this.textWrapper.find('span').on('click', function(event) {
        HandshqApp.domHelper.toggleElements(_this.textWrapper, _this.textEditor)
        _this.textField.focus()
        event.stopPropagation()
      })
    }

    this.cancelClick = function() {
      _this.cancelButton.on('click', function(event) {
        fireCallbacksAndChecks({
          regularCallback: 'regularCancelCallback',
          definedCallback: 'cancelCallback'
        })

        event.stopPropagation()
      })
    }

    this.submitForm = function() {
      _this.submitButton.on('click', function(event) {
        _this.submitButton.attr('data-disabled', true)
        _this.saveWysiwygEditor()

        event.preventDefault()

        asyncRequest({
          success: function(response) {
            clearErrors()
            fireCallbacksAndChecks({ response: response, regularCallback: 'regularSuccessCallback', definedCallback: 'successCallback' })
            _this.submitButton.attr('data-disabled', false)
          },
          error: function(response) {
            displayErrors(response)
            fireCallbacksAndChecks({ response: response, regularCallback: 'regularFailureCallback', definedCallback: 'failureCallback' })
            _this.submitButton.attr('data-disabled', false)
          }
        })
      })
    }

    this.saveWysiwygEditor = function() { if (_this.wysiwygEditor) { _this.wysiwygEditor.save() } }

    this.regularSuccessCallback = function(response) {
      _this.textWrapper.find('span').removeClass().text(response['data']['attributes'][_this.attribute])
      _this.submitButton.attr('data-disabled', false)
      HandshqApp.domHelper.toggleElements(_this.textEditor, _this.textWrapper)
    }

    this.regularFailureCallback = function(_response) {}

    this.regularCancelCallback = function() { HandshqApp.domHelper.toggleElements(_this.textEditor, _this.textWrapper) }

    this.reset = function() {
      _this.submitButton.attr('data-disabled', false)
      _this.textField.val('')
      _this.triggerTextFieldValueCheck()
    }

    function asyncRequest(options) {
      const form = _this.submitButton.closest('form');

      $.ajax({
        type: form.attr('method'),
        data: form.serialize(),
        dataType: 'json',
        url: form.attr('action'),
        success: options['success'],
        error: options['error']
      })
    }

    function clearErrors() {
      if ($('.js__errors').length) {
        HandshqApp.jsErrors.hideErrors()
        _this.textField.removeClass(textFieldInvalidClass)
      }
    }

    function displayErrors(response) {
      const errors = (response.responseJSON || {}).errors;

      if ($('.js__errors').length && typeof errors !== 'undefined') {
        HandshqApp.jsErrors.displayErrors(errors)
        _this.textField.addClass(textFieldInvalidClass)
      }
    }

    function fireCallbacksAndChecks(args) {
      if (_this.regularCallbacks) { _this[args['regularCallback']](args['response']) }
      if (_this[args['definedCallback']]) { _this[args['definedCallback']](_this, args['response']) }

      _this.triggerTextFieldValueCheck()
    }

    function validatableTextField() { return (!_this.wysiwyg && _this.attribute !== 'caption') }

    function wysiwygSetupCallback(editor) {
      editor.on('init', function() {
        _this.spinner.fadeOut('fast', function() {
          _this.textEditor.fadeIn('fast')
        })
      })
    }

    function wysiwygInitInstanceCallback(editor) {
      editor.on('paste change input undo redo', function() {
        _this.wysiwygEditor = editor;

        if (_this.wysiwygAutosave) {
          _this.saveWysiwygEditor()
          clearTimeout(autosave)

          if (_this.wysiwygFlag) {
            clearTimeout(clearFlag)
            _this.wysiwygFlag.html(_this.wysiwygFlag.attr('data-wysiwyg-flag-editing'))
          }

          autosave = setTimeout(function() {
            asyncRequest({
              success: function() {
                if (_this.wysiwygFlag) {
                  _this.wysiwygFlag.html(_this.wysiwygFlag.attr('data-wysiwyg-flag-persisted'))
                  clearFlag = setTimeout(function() { _this.wysiwygFlag.empty() }, 3000);
                }
              },
              error: function() {
                HandshqApp.GlobalToastRackAPI.addInedibleToast()
                _this.wysiwygFlag.empty()
              }
            })
          }, 500)
        } else {
          _this.wysiwygActions.show()
        }
      })
    }
  } else {
    throw new Error('HandshqApp.EditableTextarea invoked without new')
  }
}

HandshqApp.editableTextareaFactory = {
  regularTextAreas: function(node) {
    const _this = this;

    node.find('.editable-textarea').each(function() {
      _this.regularTextArea.call(this)
    })
  },

  customTextAreas: function(node, customTextarea) {
    node.find('.editable-textarea').each(function() {
      customTextarea.call(this)
    })
  },

  regularTextArea: function() {
    new HandshqApp.EditableTextarea({
      node: this,
      regularCallbacks: true
    }).setup()
  }
}
