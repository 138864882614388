import React, { useEffect, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import CollectionSelect from 'components/application/CollectionSelect';
import TextField from 'components/application/TextField';
import TextArea from 'components/application/TextArea';
import ErrorMessage from 'components/application/ErrorMessage';
import LevelOfRiskIcon from 'components/projectCoshh/LevelOfRiskIcon';

import { uniquePersonsAtRiskOptions } from 'components/helpers/resources/coshhDocuments';

import usePrevious from 'components/hooks/usePrevious';

const levelOfRiskOptions = ['Low', 'Medium', 'High'].map((level) => {
  return { label: <LevelOfRiskIcon option={level}/>, value: level }
});

export default function CoshhForm(props) {
  const {
    coshhDocument,
    onCoshhInputChange,
    onCoshhOptionChange,
    onOptionSelect,
    workerExposureTimeOptions,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    personsAtRiskOptions,
    secondaryFieldLabel,
    requestError,
    removeErrorStyling,
    sidePanelFieldRef
  } = props;

  const durationOfExposureTooltip = (
    <>
      <p>How long the person is likely to be in contact with the product. Maximum/workplace exposure limit for product:</p>
      <ul className='p-l-15'>
        <li className='tw-font-medium'>Long term exposure limit (LTEL 8hr TWA):</li>
          <p className='m-0'>{ coshhDocument.longTermExposureLimit || 'N/A' }</p>
        <li className='tw-font-medium'>Short term exposure limit (STEL 15min TWA):</li>
          <p className='m-0'>{ coshhDocument.shortTermExposureLimit || 'N/A' }</p>
      </ul>
    </>
  );

  const controlMeasuresTooltip = (
    <>
      <p>Steps to reduce the level of risk. Control measures should include:</p>
      <ul className='p-l-15'>
        <li>Use of natural or local exhaust ventilation (LEV)</li>
        <li>Exposure monitoring</li>
        <li>Health surveillance</li>
        <li>Information, instruction and training</li>
        <li>Emergency actions</li>
      </ul>
    </>
  );

  const displayWorkerExposureTimeOtherField = coshhDocument.workerExposureTime === secondaryFieldLabel;
  const displayExposureFrequencyOtherField = coshhDocument.exposureFrequency === secondaryFieldLabel;
  const displayUsesOtherField = coshhDocument.uses === secondaryFieldLabel;

  const selectOtherFieldRef = useRef();
  const displayPersonsAtRiskOtherField = Boolean(coshhDocument.personsAtRisk.find((persons) => persons.name === secondaryFieldLabel));
  const previousDisplayPersonsAtRiskOtherField = usePrevious(displayPersonsAtRiskOtherField);
  const personsAtRiskOtherOptionChangesToSelected = previousDisplayPersonsAtRiskOtherField === false && displayPersonsAtRiskOtherField;

  useEffect(() => {
    if (personsAtRiskOtherOptionChangesToSelected) {
      focusField(selectOtherFieldRef)
    }
  }, [personsAtRiskOtherOptionChangesToSelected])

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <ErrorMessage validationErrors={requestError.validationErrors} isFallback={requestError.isFallback} withSeparateNestedErrors={true}/>
      <div className='form-container'>
        <div className='form-container-section'>
          <TextField label='Quantity kept on site' name='quantityOnSite' inputRef={sidePanelFieldRef} value={coshhDocument.quantityOnSite} customMargin={'m-b-32'} maxLength={255} placeholder='e.g. 10 litres'  onChange={onCoshhInputChange} tooltip={'The average quantity being stored on site'}/>
          <TextField label='Quantity expected to be used' name='quantityToBeUsed' value={coshhDocument.quantityToBeUsed} customMargin={'m-b-32'} maxLength={255} placeholder='e.g. 10 litres' onChange={onCoshhInputChange} tooltip={'How much will be used on the project'}/>
          <TextField label='Quantity used in one day' name='substanceQuantityPerDay' value={coshhDocument.substanceQuantityPerDay} customMargin={'m-b-32'} maxLength={255} placeholder='e.g. 1 litre' onChange={onCoshhInputChange} tooltip={'The average quantity used in one day'}/>
          <CollectionSelect
            label='Method of use'
            name='uses'
            placeholder='Select method'
            value={coshhDocument.uses ? { label: coshhDocument.uses, value: coshhDocument.uses } : ""}
            isClearable={true}
            options={methodOfUseOptions.map(option => ({ label: option, value: option }))}
            onChange={onCoshhOptionChange}
            tooltip={"How the product will be used. Select 'Other' to specify a method not included in the list"}
            maxMenuHeight={320}
            customMargin={displayUsesOtherField ? 'm-b-8' : 'm-b-32'}
          />
          {
            displayUsesOtherField && (
              <TextField
                withLabel={false}
                label=''
                name={'methodOfUseOther'}
                value={coshhDocument.methodOfUseOther}
                maxLength={255}
                isRequired={false}
                placeholder={'e.g. applied by hand'}
                onChange={onCoshhInputChange}
                fieldError={requestError.validationErrors.methodOfUseOther}
                removeErrorStyling={removeErrorStyling}
              />
            )
          }
          <TextField label='Number of people involved in activity' name='staffNumber' value={coshhDocument.staffNumber} customMargin={'m-b-32'} maxLength={255} placeholder='e.g. 2 personnel' onChange={onCoshhInputChange} tooltip={'The number of people who will carry out this task'}/>
          <CollectionSelect
            label='Person(s) at risk'
            name='personsAtRisk'
            placeholder='Select person(s)'
            value={coshhDocument.personsAtRisk ? coshhDocument.personsAtRisk : ""}
            isSearchable={false}
            isMulti={true}
            options={uniquePersonsAtRiskOptions(personsAtRiskOptions, coshhDocument.personsAtRisk, secondaryFieldLabel)}
            onChange={onOptionSelect}
            tooltip={'People who could be harmed when using the product'}
            maxMenuHeight={'unset'}
            externalProps={{
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              backspaceRemovesValue: false
            }}
            checkboxes={true}
            customMargin={displayPersonsAtRiskOtherField ? 'm-b-8' : 'm-b-32'}
          />
          {
            displayPersonsAtRiskOtherField && (
              <TextField
                inputRef={selectOtherFieldRef}
                withLabel={false}
                label=''
                name={'personsAtRiskOther'}
                value={coshhDocument.personsAtRiskOther}
                maxLength={255}
                isRequired={false}
                placeholder={'e.g. Security'}
                onChange={onCoshhInputChange}
                fieldError={requestError.validationErrors.personsAtRiskOther}
                removeErrorStyling={removeErrorStyling}
              />
            )
          }
          <CollectionSelect
            label='Duration of exposure'
            name='workerExposureTime'
            placeholder='Select duration'
            value={coshhDocument.workerExposureTime ? { label: coshhDocument.workerExposureTime, value: coshhDocument.workerExposureTime } : ""}
            isSearchable={false}
            isClearable={true}
            options={workerExposureTimeOptions.map(option => ({ label: option, value: option }))}
            onChange={onCoshhOptionChange}
            tooltip={durationOfExposureTooltip}
            tooltipClassName='tooltip-dark--text-left'
            alignTooltipCenter={false}
            maxMenuHeight={'unset'}
            customMargin={displayWorkerExposureTimeOtherField ? 'm-b-8' : 'm-b-32'}
          />
          {
            displayWorkerExposureTimeOtherField && (
              <TextField
                withLabel={false}
                label=''
                name={'workerExposureTimeOther'}
                value={coshhDocument.workerExposureTimeOther}
                maxLength={255}
                isRequired={false}
                placeholder={'e.g. 16 hours'}
                onChange={onCoshhInputChange}
                fieldError={requestError.validationErrors.workerExposureTimeOther}
                removeErrorStyling={removeErrorStyling}
              />
            )
          }
          <CollectionSelect
            label='Frequency of exposure'
            name='exposureFrequency'
            placeholder='Select frequency'
            value={coshhDocument.exposureFrequency ? { label: coshhDocument.exposureFrequency, value: coshhDocument.exposureFrequency } : ""}
            isSearchable={false}
            isClearable={true}
            options={exposureFrequencyOptions.map(option => ({ label: option, value: option }))}
            onChange={onCoshhOptionChange}
            tooltip={'How often person(s) is/are likely to be in contact with the product'}
            maxMenuHeight={'unset'}
            customMargin={displayExposureFrequencyOtherField ? 'm-b-8' : 'm-b-32'}
          />
          {
            displayExposureFrequencyOtherField && (
              <TextField
                withLabel={false}
                label=''
                name={'exposureFrequencyOther'}
                value={coshhDocument.exposureFrequencyOther}
                maxLength={255}
                isRequired={false}
                placeholder={'e.g. 2 times a week'}
                onChange={onCoshhInputChange}
                fieldError={requestError.validationErrors.exposureFrequencyOther}
                removeErrorStyling={removeErrorStyling}
              />
            )
          }
          <CollectionSelect
            label='Location of use'
            name='location'
            placeholder='Select location'
            value={coshhDocument.location ? { label: coshhDocument.location, value: coshhDocument.location } : ""}
            isSearchable={false}
            isClearable={true}
            options={locationOptions.map(option => ({ label: option, value: option }))}
            onChange={onCoshhOptionChange}
            tooltip={'Where is the product going to be used'}
            maxMenuHeight={'unset'}
            customMargin={'m-b-32'}
          />
          <CollectionSelect
            label='Level of risk'
            name='levelOfRisk'
            placeholder='Select risk level'
            value={coshhDocument.levelOfRisk ? { label: <LevelOfRiskIcon subtextProps={{ className: 'mw-200' }} option={coshhDocument.levelOfRisk}/>, value: coshhDocument.levelOfRisk } : ""}
            isSearchable={false}
            isClearable={true}
            modifiers={['enlarged']}
            options={levelOfRiskOptions}
            onChange={onCoshhOptionChange}
            maxMenuHeight={'unset'}
            tooltip={'Risk level of using the product(s) before any controls, based upon the likelihood and severity of an incident'}
            customMargin={'m-b-32'}
          />
          <TextArea
            label='Control measures and additional information'
            name='additionalInformation'
            value={coshhDocument.additionalInformation}
            onChange={onCoshhInputChange}
            tooltip={controlMeasuresTooltip}
            tooltipClassName='tooltip-dark--text-left'
            resizeVertical={true}
          />
        </div>
      </div>
    </div>
  )
}

CoshhForm.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  onCoshhInputChange: PropTypes.func.isRequired,
  onCoshhOptionChange: PropTypes.func.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired
}
