import React, { useEffect, useMemo } from 'react';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DownloadIcon from '-!svg-react-loader?name=DownloadIcon!icons/ic-download.svg';
import Tooltip from 'components/application/Tooltip';
import useDownloadPolling from 'components/hooks/useDownloadPolling';
import ActivityTableData from 'components/projectActivity/ActivityTableData';
import DownloadModalWrapper from 'components/application/DownloadModalWrapper';
import Polling from 'handshq-app/utilities/polling';

export default function ProjectActivityRow(props) {
  const {
    isVersionPdfsEnabled,
    history,
    downloadDisabled,
    projectId,
    currentUser
  } = props

  const statusPoller = useMemo(() => {
    return new Polling({
      interval: 1000,
      url: `/versions/${history.versionId}`,
      responseCheck: function(response) {
        return response.data.relationships.version_pdf.data !== null
      },
      successCallback: (response) => {
        setDownloadState({...downloadState, downloadFailed: false, downloadInProgress: false, downloadSucceeded: true, downloadLink: response.included[0].links.content})
      },
      failureCallback: (_response) => {
        setDownloadState({...downloadState, downloadInProgress: false, downloadFailed: true})
      }
    });
  }, [])

  const [onClick, startDownload, resetAction, closeModal, modalIsOpen, downloadState, setDownloadState] = useDownloadPolling(statusPoller)

  const downloadVersion = () => {
    window.open(downloadState.downloadLink, '_blank');
  };

  const downloadButton = (
    <OutlinedButton size='sm' color='grey' disabled={downloadDisabled} onClick={downloadDisabled ? () => {} : onClick}>
      <DownloadIcon className='m-l--8' width={24} height={24}/>
      <span>Download</span>
    </OutlinedButton>
  );

  const analyticProps = {
    projectId: projectId,
    versionId: history.versionId,
    currentUser: currentUser
  };

  useEffect(() => {
    return () => {
      statusPoller && statusPoller.end()
    }
  }, [])

  return (
    <tr className='handshq__tr--taller hover:tw-bg-grey-025'>
      <ActivityTableData
        history={history}
        showVersionNumberColumn={isVersionPdfsEnabled}
        downloadButtonVisible={isVersionPdfsEnabled && history.versionId}
      />
      {(isVersionPdfsEnabled && history.versionId) && (
        downloadDisabled ? (
          <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            <Tooltip placement='top' trigger='hover' tooltip='You cannot download a project until it has been approved'>
              {downloadButton}
            </Tooltip>
          </td>
        ) : (
          <td className='handshq__td-button-cell--align-right tw-border-grey-100'>
            {downloadButton}
            {modalIsOpen &&
              <DownloadModalWrapper
                analyticTrackingId={'Project history version downloaded'}
                analyticProps={analyticProps}
                successBodyText='PDF ready to download'
                errorBodyText='There was a problem creating the PDF. Please try again'
                downloadingBodyText='Downloading...'
                headerText='Download document'
                modalIsOpen={modalIsOpen}
                downloadStatus={downloadState}
                retryAction={startDownload}
                downloadAction={downloadVersion}
                resetAction={resetAction}
                closeModal={closeModal}
                isLandscape={true}
              />
            }
          </td>
        )
      )}
    </tr>
  )
}
