import MethodText from './method-text';

function _MethodTexts( methodTexts ) {
  const _this = this;
  this.collection = [];
  this.methodTexts = $( methodTexts );

  this.methodTexts.each(function(_i, el) {
    _this.collection.push(new MethodText(el));
  });
}
