import { tinymceMethodTextInit } from '../../../shared/wysihtml';

function MethodText ( methodTextElement ) {
  const _this = this;
  this.textArea = $( methodTextElement ).find( 'textarea.method-text' )[0];
  this.spinner = $( methodTextElement ).find( '.method-text-spinner' );
  this.container = $( methodTextElement ).find( '.method_text_container' );
  this.id = $( this.textArea ).data( 'method-text-id' );
  this.coloursAllowed = $( this.textArea ).data( 'colours-allowed');

  this.submit = function() {
    $( methodTextElement ).find( '.submit' )[0].click();
  };

  // Activate the rich text editing iframe
  if ( $(methodTextElement).find( ".rich-text" ).length > 0 ) {
    tinymceMethodTextInit( _this );
  };
}

export default MethodText;
