import React from 'react';
import DestroyModal from 'components/application/DestroyModal';

export default function BookingDestroyModal(props) {
  const { isOpen, onClose, booking, course, onDestroy } = props;
  const courseHasELearningCourse = !!(course && course.relationships.eLearningCourse.data);

  const additionalDisplayText = courseHasELearningCourse ? (
    'You will be refunded the credit as the course has not been started.'
  ) : (
    'Email notifications will be sent to the personnel and their line manager (if they have email addresses).'
  )

  return (
    <DestroyModal
      isOpen={isOpen}
      displayText={`Are you sure you want to remove the booking for ${course && course.attributes.name}?`}
      confirmationText='Remove booking'
      additionalContent={
        <p className='tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 m-0 m-t-20'>
          {additionalDisplayText}
        </p>
      }
      onClose={onClose}
      onDestroy={() => onDestroy({ bookingId: booking.id, isNotified: true })}
    />
  )
}
