export default function setupPolling({ requestFn, testFn, intervalTime = 2000, maxTime = 60000 }) {
  let isPolling = false;
  let rejectPromise = null;
  let num = 0;

  const maxNum = Math.ceil(maxTime / intervalTime);

  const startPolling = () => {
    return new Promise((resolve, reject) => {
      isPolling = true;
      rejectPromise = reject;

      const executePoll = async () => {
        if (num++ === maxNum) {
          isPolling = false;
          reject(new Error("Polling limit reached"))
        } else {
          try {
            const response = await requestFn();

            if (testFn(response)) {
              isPolling = false;
              resolve(response)
            } else {
              setTimeout(executePoll, intervalTime)
            }
          } catch(error) {
            isPolling = false;
            reject(new Error("Polling error raised"))
          }
        }
      }

      setTimeout(executePoll, intervalTime)
    })
  }

  const endPolling = () => {
    if (isPolling) {
      isPolling = false;
      rejectPromise(new Error("Polling manually ended"))
    }
  }

  return {
    startPolling,
    endPolling
  }
}
