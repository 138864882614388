import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import { subcontractorDisplayName } from 'components/helpers/companies';

import BlockList from 'components/application/BlockList';
import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';
import CourseBookedProgressPill from 'components/courses/pills/CourseBookedProgressPill';

export default function CourseSidePanelPersonnelTab(props) {
  const {
    course,
    personnel,
    loadMoreRef,
    isLoadMorePersonnelActive
  } = props;

  const personnelBookingsMap = personnel.includedBookings.reduce((acc, booking) => {
    acc[booking.relationships.personnel.data.id] = booking;
    return acc
  }, {});

  let personnelRegistrationsMap = {};
  if (course.isELearningProviderSelected) {
    personnelRegistrationsMap = personnel.includedRegistrations.reduce((acc, registration) => {
      acc[registration.relationships.personnel.data.id] = registration;
      return acc
    }, {});
  }

  const personnelTrainingsMap = personnel.collection.reduce((acc, person) => {
    const matchingTraining = personnel.includedTrainings.find(trainingItem => trainingItem.relationships.personnel.data.id === person.id);
    acc[person.id] = matchingTraining;
    return acc;
  }, {});

  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();

  return (
    <div key='personnelTab' label='personnel'>
      {personnel.loaded && personnel.collection.length === 0 ? (
        <li className='block-list__item tw-border-grey-100'>
          <div className='block-list__item--flex-container'>
            <div className='block-list__item--align-center'>
              <span>No active personnel require or have completed this course</span>
            </div>
          </div>
        </li>
      ) : (
        <>
          <BlockList
            collection={personnel.collection}
            blockListItemClasses={(person) => person.attributes.subcontractorId ? ' block-list__item--triple' : '' }
            onItemClick={trainingRegisterResourceManagementContext.hasPersonnelViewableAccess ? ((person) => window.location.replace(`personnel/${person.id}`)) : undefined}
            onItemDisplay={(person) => {
              const booking = personnelBookingsMap[person.id];

              let registration = null;
              if (course.isELearningProviderSelected) { registration = personnelRegistrationsMap[person.id]; }

              return(
                <div className='block-list__item--flex-container'>
                  <div className='block-list__item--align-start'>
                    <PersonnelCourseTrainingStatusIndicator
                      status={person.attributes.trainingStatus}
                    />
                  </div>
                  <div className='block-list__item--text mw-300'>
                    <div className='flex mw-300'>
                      <span className='truncated-text-container truncated-text-container--width-auto'>
                        {`${person.attributes.firstName} ${person.attributes.lastName}`}
                      </span>
                      {booking && (
                        <CourseBookedProgressPill
                          isELearningProviderSelected={course.isELearningProviderSelected}
                          booking={booking}
                          registration={registration}
                        />
                      )}
                    </div>
                    {person.attributes.subcontractorId && (
                      <span className='block-list__item--sub-text-triple truncated-text-container tw-text-s tw-text-grey-500 tw-tracking-wide'>{subcontractorDisplayName(person.attributes.subcontractorId, personnel.includedSubcontractors)}</span>
                    )}
                    <span className='block-list__item--sub-text-triple truncated-text-container tw-text-s tw-text-grey-500 tw-tracking-wide'>
                      {getTrainingDescription(person.attributes.trainingStatus, course, personnelTrainingsMap[person.id])}
                    </span>
                  </div>
                </div>
              )
            }}
          />
          {isLoadMorePersonnelActive && !personnel.meta.isLastPage && (
            <li ref={loadMoreRef} className='block-list__item tw-border-grey-100'>
              <div className='block-list__item--flex-container'>
                <div className='block-list__item--align-center'>
                  <span>Loading personnel...</span>
                </div>
              </div>
            </li>
          )}
        </>
      )}
    </div>
  )
}

const trainingStatusDefinitionMap = {
  0 : 'Required training is missing',
  1 : 'Required training is missing expiry date',
  2 : 'Required training has expired',
  3 : 'Required training is expiring soon',
  4 : 'Training is up to date',
  5 : 'No required training'
};

function getTrainingDescription(trainingStatus, course, latestTraining) {
  function expiryDateText(prefixText) {
    return `${prefixText} ${(moment.parseZone(latestTraining.attributes.expiryDate).format('DD MMM YYYY'))}`
  };

  switch(trainingStatusDefinitionMap[trainingStatus]) {
    case('Required training is missing'):
    case('Required training is missing expiry date'):
      return "Missing";
    case('Required training has expired'):
      return course.expires ? expiryDateText('Expired') : 'Does not expire'
    case('Required training is expiring soon'):
      return course.expires ? expiryDateText('Expires') : 'Does not expire'
    case('Training is up to date'):
      return course.expires ? expiryDateText('Expires') : 'Does not expire'
    case('No required training'):
      if (!latestTraining) {
        return "Missing but not required"
      } else {
        const isExpired = moment.parseZone(latestTraining.attributes.expiryDate) <= moment.now();
        if (course.expires && isExpired) {
          return `${expiryDateText('Expired')} but not required`
        } else if (course.expires && !isExpired) {
          return `${expiryDateText('Expires')} but not required`
        } else if (!course.expires) {
          return 'Does not expire'
        }
      }
  }
};

CourseSidePanelPersonnelTab.propTypes = {
  course: PropTypes.object.isRequired,
  personnel: PropTypes.object.isRequired,
  personnelMeta: PropTypes.object.isRequired,
  loadMoreRef: PropTypes.func.isRequired,
  isLoadMorePersonnelActive: PropTypes.bool.isRequired
}
