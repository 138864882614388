import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { v4 as uuidv4 } from 'uuid';
import { addScrollingEvents } from 'helpers/tinymce';
import 'style-loader!tinymce/skins/handshq/skin.min.css';
import contentStyle from 'tinymce/skins/handshq/content.min.css';

export default function WysiwygEditor(props) {
  const {
    name,
    value,
    onChange
  } = props;

  const uuid = useMemo(() => uuidv4(), []);

  const handleEditorChange = (content, editor) => {
    onChange({ content: content, editor: editor }, { target: { name } })
  };

  return (
    <Editor
      apiKey={process.env.TINYMCE_API_KEY}
      id={uuid}
      value={value}
      init={{
        branding: false,
        skin: false,
        menubar: false,
        statusbar: true,
        content_css: false,
        elementpath: false,
        custom_colors: false,
        content_style: contentStyle.toString(),
        body_class: 'tinyMCE-content',
        resize: true,
        min_height: 230,
        plugins: 'lists tinymcespellchecker',
        toolbar: 'bold bullist',
        spellchecker_language: 'en_uk',
        spellchecker_on_load: true,
        init_instance_callback: (editor) => {
          addScrollingEvents(editor)
        }
      }}
      onEditorChange={handleEditorChange}
    />
  )
}

WysiwygEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
