import HandshqApp from '../handshq-app';

HandshqApp.SectionsContainer = function(parent) {
  if (this instanceof HandshqApp.SectionsContainer) {
    const _this = this;

    this.parent = parent;
    this.actions = _this.parent.root.find('.category-section__actions');
    this.new = _this.parent.root.find('.category-section__new');
    this.footer = _this.parent.root.find('.category-sections-footer');
    this.sectionName = _this.parent.root.find('.category-section__name');
    this.sectionContent = _this.parent.root.find('.category-section__content');
    this.footerNew = _this.parent.root.find('.category-sections-footer__new-section');

    this.setup = function() {
      HandshqApp.categoryFormHelper.setupActions({ resource: 'category-section', container: _this });
      HandshqApp.editableTextareaFactory.customTextAreas(_this.footerNew, setupAddSectionTextArea);
      HandshqApp.editableTextareaFactory.regularTextAreas(_this.sectionName);

      return this;
    };

    function setupAddSectionTextArea() {
      new HandshqApp.EditableTextarea({
        node: this,
        successCallback: addSectionSuccessCallback,
        cancelCallback: addSectionCancelCallback
      }).setup();
    }

    function addSectionSuccessCallback(editableTextarea, response) {
      const sectionsCollection = editableTextarea.node.closest('.category-sections');
      const section = $(response['data']['attributes']['htmlContent']);
      const deleteModal = $(response['data']['attributes']['htmlDelete']);

      section.insertBefore(sectionsCollection.find('tbody.category-sections__end'));
      deleteModal.insertBefore(sectionsCollection.find('table.category-sections__end'));

      _this.editableContent.setupWysiwygTextArea.call(section.find('.category-section__content .editable-textarea'));
      _this.editableContent.setupUploaderImageArea.call(section.find('.image-uploader'));

      HandshqApp.categoryFormHelper.setupDeleteModal({ resource: 'category-section', contentNode: section, modalNode: deleteModal });
      HandshqApp.editableTextareaFactory.regularTextArea.call(section.find('.category-section__name .editable-textarea'));
      HandshqApp.domHelper.toggleElements(_this.footer, _this.new);

      ReactRailsUJS.mountComponents(section[0])

      editableTextarea.reset();
    }

    function addSectionCancelCallback(_editableTextarea) {
      HandshqApp.domHelper.toggleElements(_this.footer, _this.new);
    }

    this.editableContent = new HandshqApp.EditableContent(_this.sectionContent).setup();

  } else {
    throw new Error('HandshqApp.SectionsContainer invoked without new');
  }
};
