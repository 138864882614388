import React from 'react';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import PropTypes from 'prop-types';
import voca from 'voca';
import pluralize from 'pluralize';
import { useBreadBoard } from 'components/contexts/Toaster';
import { scrollToBottom } from 'components/helpers/scrolling';

import TextField from 'components/application/TextField';
import TextArea from 'components/application/TextArea';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import RadioField from 'components/application/RadioField';
import CurrencyField from 'components/application/CurrencyField';
import SearchELearningCoursesField from 'components/courses/SearchELearningCoursesField';
import NonCustomELearningCourseCard from 'components/courses/NonCustomELearningCourseCard';
import CircleQuestion from 'components/application/CircleQuestion';

const timeIntervalOptions = ['minute', 'hour', 'day', 'week'].map(option => {
  return { label: pluralize(voca.titleCase(option)), value: option }
});

const learningMethodOptions = [
  { label: 'ATP (Approved Training Provider)', value: 'approved_training_provider' },
  { label: 'Internal', value: 'internal' },
  { label: 'Online', value: 'online' }
];

export default function CourseSidePanelProviderForm(props) {
  const {
    course,
    requestError,
    removeErrorStyling,
    onInputChange,
    onOptionChange,
    onELearningProviderSelectedChange,
    bodyRef
  } = props;
  const currentActor = useCurrentActor();
  const currencyCode = currentActor.division.attributes.currencyCode;
  const breadBoard = useBreadBoard();
  return (
    <div className='form-container'>
      {currentActor.isAllowedFeature('e_learning') && (
        <>
          <label className='field__label tw-font-medium' htmlFor='isELearningProviderSelected'>
            Course details
            <Tooltip
              placement='top'
              trigger='hover'
              tooltip={
                <div className='tw-text-center'>
                  Use eLearning or add details for your own provider.<br/>
                  <span>Find out </span>
                  <a
                    className='app-link tw-font-medium tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                    target='blank'
                    href='https://intercom.help/hands-hq/en/articles/5870952-get-started-with-elearning-assign-and-book-online-training'
                  >
                    how eLearning works
                  </a>
                  <span> in Training Register.</span>
                </div>
              }
            >
              <CircleQuestion />
            </Tooltip>
          </label>
          <RadioField
            checked={course.isELearningProviderSelected}
            onChange={() => onELearningProviderSelectedChange({ selected: true })}
            value={true}
            name='isELearningProviderSelected'
            label='iHasco eLearning'
          />
          {course.isELearningProviderSelected && (
            !!course.eLearningCourseId ? (
              <NonCustomELearningCourseCard
                eLearningCourseId={course.eLearningCourseId}
                isDisabled={course.activeRegistrationCount > 0}
                disabledTooltip="Course can't be changed whilst eLearning courses are in progress"
                onClose={() => onOptionChange({ value: null }, { name: 'eLearningCourseId' })}
                onError={breadBoard.addInedibleToast}
              />
            ) : (
              <SearchELearningCoursesField
                name='eLearningCourseId'
                fieldError={requestError.validationErrors.eLearningCourseId}
                removeErrorStyling={removeErrorStyling}
                onSelect={onOptionChange}
                onError={breadBoard.addInedibleToast}
              />
            )
          )}
          <RadioField
            checked={!course.isELearningProviderSelected}
            disabled={course.activeRegistrationCount > 0}
            tooltip="Provider can't be changed whilst eLearning courses are in progress"
            onChange={() => onELearningProviderSelectedChange({ selected: false })}
            value={false}
            name='isELearningProviderSelected'
            label='Other provider'
          />
        </>
      )}
      {!course.isELearningProviderSelected && (
        <div className={currentActor.isAllowedFeature('e_learning') ? 'm-l-28' : ''}>
          <CollectionSelect
            label='Method'
            name='learningMethod'
            value={learningMethodOptions.find(option => course.learningMethod == option.value) || null}
            isRequired={false}
            isDisabled={false}
            isSearchable={false}
            options={learningMethodOptions}
            onChange={onOptionChange}
          />
          <TextField
            label='Provider'
            name='provider'
            value={course.provider}
            isRequired={false}
            removeErrorStyling={removeErrorStyling}
            fieldError={requestError.validationErrors.provider}
            onChange={onInputChange}
          />
          <CurrencyField
            label='Cost estimate'
            name='costEstimate'
            value={course.costEstimate}
            currencyCode={currencyCode}
            isRequired={false}
            removeErrorStyling={removeErrorStyling}
            fieldError={requestError.validationErrors.costEstimate}
            onChange={onInputChange}
          />
          <div className='side-panel__inline-container'>
            <div className='field--inline flex-item--grow m-r-6'>
              <TextField
                label='Time estimate'
                name='timeAmount' value={course.timeAmount}
                isRequired={false}
                removeErrorStyling={removeErrorStyling}
                fieldError={requestError.validationErrors.timeAmount}
                onChange={onInputChange}
              />
            </div>
            <div className='field--inline flex-item--grow m-l-6'>
              <CollectionSelect
                label=''
                name='timeInterval'
                value={timeIntervalOptions.find(option => course.timeInterval == option.value)}
                isRequired={false}
                isDisabled={false}
                isSearchable={false}
                options={timeIntervalOptions}
                onChange={onOptionChange}
                externalProps={{ onMenuOpen: () => scrollToBottom(bodyRef) }}
              />
            </div>
          </div>
          <div className='pos-rel'>
            <TextArea
              label='Default booking notes'
              name='defaultBookingNote'
              value={course.defaultBookingNote}
              onChange={onInputChange}
              tooltip={<div className='ta-center tw-text-s tw-tracking-wide tw-font-normal'>Notes will be included in the email when booking someone onto the course. Notes can be edited or removed when making the booking.</div>}
              placeholderText={'e.g. Please arrive 10 minutes before to sign in'}
            />
          </div>
        </div>
      )}
    </div>
  )
}

CourseSidePanelProviderForm.propTypes = {
  course: PropTypes.object.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onELearningProviderSelectedChange: PropTypes.func.isRequired,
  bodyRef: PropTypes.object.isRequired
}
