import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/application/Tabs';
import CourseSidePanelDetailsTab from 'components/courses/sidepanel/CourseSidePanelDetailsTab';
import CourseSidePanelPersonnelTab from 'components/courses/sidepanel/CourseSidePanelPersonnelTab';
import CourseSidePanelRolesTab from 'components/courses/sidepanel/CourseSidePanelRolesTab';

import useWindowStorage from 'components/hooks/useWindowStorage';

import 'intersection-observer';

export default function CourseSidePanelShow(props) {
  const {
    currentCourse,
    eLearningCourse,
    assignableRoles,
    personnel,
    isLoadMorePersonnelActive,
    sidePanelIsOpen,
    loadMoreRef
  } = props;

  const [getInitialActiveTab, setInitialActiveTab] = useWindowStorage('coursesSidePanelInitialTab', { store: window.sessionStorage });
  const initialActiveTab = getInitialActiveTab() || 'details';

  // get role name for selected courseCompanyRoles
  let rolesCollection = [];
  if(assignableRoles.length > 0){
    rolesCollection = currentCourse.courseCompanyRoles.map((ccr) => {
      const role = assignableRoles.find(role => role.id == ccr.companyRoleId);
      return { rolePosition: role.attributes.position, required: ccr.required }
    })
  }

  function handleTabClick(tab) {
    setInitialActiveTab(tab);
  }

  return (
    <div className='popup__body-show tw-p-0'>
      <Tabs
        modifiers={['panelled']}
        navClassName='tw-bg-grey-050'
        activeTabIsReset={!sidePanelIsOpen}
        initialActiveTab={initialActiveTab}
        onTabClick={handleTabClick}
      >
        <CourseSidePanelDetailsTab
          key='detailsTab'
          label='details'
          course={currentCourse}
          eLearningCourse={eLearningCourse}
        />
        <CourseSidePanelPersonnelTab
          key='personnelTab'
          label='personnel'
          course={currentCourse}
          personnel={personnel}
          loadMoreRef={loadMoreRef}
          isLoadMorePersonnelActive={isLoadMorePersonnelActive}
          disabled={!personnel.loaded || personnel.collection.length === 0}
          tooltipText='No personnel require or have completed this course'
        />
        <CourseSidePanelRolesTab
          key='rolesTab'
          label='roles'
          disabled={currentCourse.courseCompanyRoles.length === 0}
          tooltipText='No roles require this course'
          courseCompanyRoles={rolesCollection}
          />
      </Tabs>
    </div>
  )
}

CourseSidePanelShow.propTypes = {
  currentCourse: PropTypes.object.isRequired,
  eLearningCourse: PropTypes.object,
  assignableRoles: PropTypes.object.isRequired,
  personnel: PropTypes.object.isRequired,
  personnelMeta: PropTypes.object.isRequired,
  isLoadMorePersonnelActive: PropTypes.bool.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired
}
