import React from 'react';
import PropTypes from 'prop-types';

import ActionsPill from 'components/application/ActionsPill';
import CalendarIcon from '-!svg-react-loader?name=CalendarIcon!icons/calendar.svg';

const bookingTooltipOptions = {
  expiring: 'Training is expiring soon and requires booking',
  expired: 'Training is expired and requires booking',
  missing: 'Training is missing and requires booking',
  booked: 'Training is booked',
  personnelEmailUnavailable: 'Personnel must have an email address to book eLearning',
  creditInfoUnavailable: 'There was a problem displaying your remaining credits'
};

export default function ELearningBookings(props) {
  const {
    booking,
    course,
    eLearningCourse,
    registration,
    currentTrainingStatus,
    currentPersonnel,
    isCreditInfoUnavailable,
    isBookingRequired,
    onAddELearningBooking
  } = props;

  const isPersonnelEmailUnavailable = !currentPersonnel.email;

  let bookingTooltipKey;

  if (booking) {
    bookingTooltipKey = 'booked';
  } else if (isCreditInfoUnavailable) {
    bookingTooltipKey = 'creditInfoUnavailable'
  } else if (isPersonnelEmailUnavailable) {
    bookingTooltipKey = 'personnelEmailUnavailable'
  } else {
    bookingTooltipKey = currentTrainingStatus
  };

  if (registration) return (
    <ActionsPill
      color='green'
      displayText='eLearning in progress'
      icon={<CalendarIcon height={16} width={16} />}
      tooltipText={`${currentPersonnel.firstName} ${currentPersonnel.lastName} has started but not finished the course`}
    />
  )

  if (booking) return (
    <ActionsPill
      color='green'
      displayText='eLearning booked'
      icon={<CalendarIcon height={16} width={16} />}
      tooltipText={bookingTooltipOptions[bookingTooltipKey]}
    />
  )

  if (isBookingRequired) return (
    <ActionsPill
      color='cyan'
      isDisabled={isPersonnelEmailUnavailable || isCreditInfoUnavailable}
      displayText='Book eLearning'
      icon={<CalendarIcon height={16} width={16} />}
      tooltipText={bookingTooltipOptions[bookingTooltipKey]}
      onClick={() => onAddELearningBooking({ course, eLearningCourse })}
    />
  )

  return null
}

ELearningBookings.propTypes = {
  booking: PropTypes.object,
  course: PropTypes.object,
  eLearningCourse: PropTypes.object,
  registration: PropTypes.object,
  currentTrainingStatus: PropTypes.string,
  currentPersonnel: PropTypes.object,
  isCreditInfoUnavailable: PropTypes.bool,
  isBookingRequired: PropTypes.bool,
  onAddELearningBooking: PropTypes.func
}

ELearningBookings.defaultProps = {
  hasPersonnelEditableAccess: true
}
