import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';

export default function ArchiveModal(props) {
  const {
    isOpen,
    closeModal,
    isArchived,
    longName,
    shortName,
    ramsPluralName,
    hasELearningBookings,
    unarchiveBody,
    onArchive,
    onUnarchive
  } = props;

  const currentActor = useCurrentActor();

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={closeModal}>
        Cancel
      </TextButton>
      {isArchived ? (
        <FilledButton color='mint' onClick={onUnarchive}>
          {`Unarchive ${shortName}`}
        </FilledButton>
      ) : (
        <FilledButton color='red' onClick={onArchive}>
          {`Archive ${shortName}`}
        </FilledButton>
      )}
    </div>
  );

  const archiveBody = (
    <div className='m-t-16'>
      <p className='m-b-8'>Archived personnel:</p>
      <ul className='app-ul'>
        {!currentActor.isAllowedFeature('training_register_only') && <li className='flex'><CrossIcon className='[&_polygon]:tw-fill-red-600' height={20} width={20} /><span>Can't be added to {ramsPluralName}</span></li>}
        <li className='flex'><CrossIcon className='[&_polygon]:tw-fill-red-600' height={20} width={20} /><span>Notifications won't be sent for expiring training</span></li>
        <li className='flex'><TickIcon className='[&_polygon]:tw-fill-green-500' height={20} width={20} /><span>Can view profile and restore the personnel</span></li>
      </ul>
      {hasELearningBookings && (
        <p className='tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 m-0 m-t-20'>
          This personnel has eLearning booked which will be cancelled. Any courses in progress will not be refunded.
        </p>
      )}
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={closeModal}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <h2 className='tw-text-grey-900 tw-text-l tw-font-semibold m-0 p-0'>Are you sure you want to {isArchived ? 'unarchive' : 'archive'} {longName}?</h2>
        {isArchived ? unarchiveBody : archiveBody}
      </div>
    </ModalBox>
  )
}

ArchiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
  longName: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  ramsPluralName: PropTypes.string,
  unarchiveBody: PropTypes.object,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired
};

ArchiveModal.defaultProps = {
  unarchiveBody: null,
  ramsPluralName: 'projects',
};
