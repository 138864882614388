import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import moment from 'moment';
import { resourceShape } from 'components/helpers/serialisableResources'
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import BookingSidePanelShow from 'components/training/BookingSidePanelShow.jsx'
import BookingSidePanelForm from 'components/training/BookingSidePanelForm.jsx'

export default function BookingSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const {
    personnel,
    currentBooking,
    onCancel,
    onSubmit,
    submitDisabled,
    requestError,
    removeErrorStyling,
    sidePanelIsOpen,
    onInputChange,
    onDateChange,
    sidePanelContext,
    domainBooking,
    domainBooker,
    domainCourse,
    onEditBookingClick,
    onBookingUpdateSubmit,
    onRemoveBooking,
    onBookingReminder
  } = props

  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();

  const header = (
    <div className='popup__title popup__title--tertiary-split'>
      {sidePanelContext !== 'show' && (
        <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>{sidePanelContext === 'new' ? 'Add booking for' : 'Edit booking'}</h1>
      )}
      <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>{domainCourse?.attributes.name}</h2>
    </div>
  )

  const body = sidePanelContext === 'show' ? (
    <BookingSidePanelShow
      booking={domainBooking}
      booker={domainBooker}
      course={domainCourse}
      sidePanelIsOpen={sidePanelIsOpen}
    />
  ) : (
    <BookingSidePanelForm
      requestError={requestError}
      currentBooking={currentBooking}
      domainBooking={domainBooking}
      removeErrorStyling={removeErrorStyling}
      onInputChange={onInputChange}
      sidePanelContext={sidePanelContext}
      onDateChange={onDateChange}
    />
  )

  const footer = () => {
    switch(sidePanelContext) {
      case 'new': return (
        <FormFooter
          text={'Add booking'}
          onCancel={onCancel}
          onSubmit={() => onSubmit(currentBooking.courseName)}
          submitButtonDisabled={submitDisabled}
          requestError={requestError}
        />
      )
      case 'show': return (
        // reducer for deletion is updating before the update of domain information
        trainingRegisterResourceManagementContext.hasBookingEditableAccess && domainCourse && domainBooking && (
          <>
            <div className={'tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 fh-58'}>
              {!personnel.email ? (
                <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='Personnel has no email'>
                    <TextButton size='full' color='blue' disabled={true}>
                      Send reminder
                    </TextButton>
                </Tooltip>) : (
                <TextButton size='full' color='blue' onClick={() => onBookingReminder(domainBooking.id)} disabled={false}>
                  Send reminder
                </TextButton>
              )}
            </div>
            <ShowFooter
              editType='booking'
              resource={domainCourse.attributes.name}
              onEditClick={onEditBookingClick}
              editDisabled={!!(moment.parseZone(domainBooking.attributes.date).toDate() < new Date())}
              editDisabledTooltip={"Past bookings can't be edited"}
              onDeleteClick={() => onRemoveBooking({bookingId: domainBooking.id})}
            />
          </>
        )
      )
      case 'edit': return (
        <FormFooter
          text='Save changes'
          submitButtonDisabled={submitDisabled}
          onSubmit={onBookingUpdateSubmit}
          onCancel={onCancel}
        />
      )
  }}

  return (
    <SidePanel
      color='tertiary'
      isOpen={sidePanelIsOpen}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onCancel}
      closeCallback={onCancel}
      headerContent={header}
      bodyContent={body}
      footerContent={footer()}
      submitDisabled={submitDisabled}
      contentContext={sidePanelContext}
    />
  )
}

BookingSidePanel.propTypes = {
  currentBooking: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  requestError: PropTypes.object,
  removeErrorStyling: PropTypes.func,
  sidePanelIsOpen: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  sidePanelContext: PropTypes.string,
  onEditBookingClick: PropTypes.func.isRequired,
  domainBooking: resourceShape('booking'),
  domainBooker: resourceShape('simpleUser'),
  domainCourse: resourceShape('course'),
  onRemoveBooking: PropTypes.func.isRequired,
  onBookingReminder: PropTypes.func.isRequired
}
