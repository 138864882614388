

import HandshqApp from './handshq-app';

HandshqApp.rails = {
  testEnv: "false",
  recurlyPublicKey: "ewr1-78LAsRt5QXIkgoxxE9dFpR",
  loadingIconSvgPath: "//cdn.app.handshq.com/assets/icons/loading-icon-709faf0fc374785f71c0253315ec93de26871c03c39c5d26c9b860320b1900db.svg",
  imageIconSvgPath: "//cdn.app.handshq.com/assets/icons/image-53a27234156717490a70a58aa5720e32196ab472bc0db68bc2fffdb73df1d732.svg",
  trashIconSvgPath: "//cdn.app.handshq.com/assets/icons/trash-0055a6f1358673ed074819a5111c708c66c8514444bd7816abcfcc2ef09b1e5c.svg",
  refreshIconSvgPath: "//cdn.app.handshq.com/assets/icons/refresh-2d0d94e5a80ae696cd4cff005c798a4581b12f1c65ea71a6fb3c36a3bc45e3c6.svg",
  chevronUpIconSvgPath: "//cdn.app.handshq.com/assets/icons/chevron-up-66cfd2e5dc400b09900d6894aac71e85cda39e166a22de33afb3d7a4ba124daf.svg",
  chevronDownIconSvgPath: "//cdn.app.handshq.com/assets/icons/chevron-down-7309b2ce99ae19d27af01f5b66f956c64805285e4181bbf23b0406d8843721f4.svg",
  avatarPaths: {
    michelleKimbler: "//cdn.app.handshq.com/assets/avatars/michelle-kimbler-d9f90c50de1e56ca04c7f502c08fb5e81ce0ad282010a1393701d7502a6bb5ae.png"
  }
};
