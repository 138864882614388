import React from 'react';
import PropTypes from 'prop-types';

export default function Switch(props) {
  const { className, disabled, onLabel, offLabel, checked, value, name } = props;

  return (
    <div className={`app-switch${disabled ? ` tw-opacity-30` : ''}${className ? ` ${className}` : ''}`}>
      <input
        type='checkbox'
        className='tw-peer'
        checked={checked}
        value={value}
        name={name}
        readOnly={true}
      />
      <label className="tw-text-grey-700 tw-bg-grey-200 hover:tw-bg-blue-050 group-hover/option:tw-bg-blue-050 peer-checked:tw-text-white peer-checked:tw-bg-blue-400 peer-checked:hover:tw-bg-blue-500 group-hover/option:peer-checked:tw-bg-blue-500 after:tw-bg-white after:tw-content-['']" data-switch-on={onLabel} data-switch-off={offLabel} />
    </div>
  )
}

Switch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string,
  name: PropTypes.string
};

Switch.defaultProps = {
  disabled: false,
  onLabel: 'yes',
  offLabel: 'no',
  value: '',
  name: ''
};
