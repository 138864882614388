import React from 'react';
import TextArea from 'components/application/TextArea';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function SubmittableField(props) {
  const {
    value,
    onSubmit,
    onCancel,
    submitButtonText,
    autoFocus,
    submitIsDisabled,
    onChange,
    submitFieldPlaceholderText
  } = props;

  const isSubmittable = Boolean(value) && value.trim().length > 0;

  const handleSubmit = (e) => {
    isSubmittable && onSubmit(e, value)
  }

  return (
    <React.Fragment>
      <TextArea
        labelVisible={false}
        autoHeight={true}
        padded={true}
        name={'submittableFieldValue'}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        smallFontSize={true}
        placeholderText={submitFieldPlaceholderText}
      />
      <TextButton className='toggleable-field__cancel m-r-12' size='sm' color='blue' onClick={onCancel}>
        Cancel
      </TextButton>
      {!isSubmittable ? (
        <Tooltip
          placement='top'
          trigger='hover'
          tooltip="Text field can't be blank"
        >
          <OutlinedButton className='toggleable-field__submit' size='sm' color='grey' disabled={true} onClick={handleSubmit}>
            {submitButtonText}
          </OutlinedButton>
        </Tooltip>
      ) : (
        <OutlinedButton className='toggleable-field__submit' size='sm' color='grey' disabled={submitIsDisabled} onClick={handleSubmit}>
          {submitButtonText}
        </OutlinedButton>
      )}
    </React.Fragment>
  )
}
SubmittableField.defaultProps = {
  value: '',
  submitFieldPlaceholderText: null
}
