import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';

import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import Tooltip from 'components/application/Tooltip';
import Switch from 'components/application/Switch';
import { sendAnalytics } from 'components/helpers/analytics';

export default function TrainingOptions({
  showHistoricalTrainingOnly,
  isRoleRequired,
  isRequiredByAnotherRole,
  isRequiredStatus,
  isAdditional,
  isMultipleTraining,
  isHistoricalTrainingVisible,
  courseHasELearningCourse,
  personnelId,
  currentPersonnel,
  training,
  course,
  eLearningCourse,
  booking,
  registration,
  userCourse,
  updateUserCourse,
  createUserCourse,
  creditInfoUnavailable,
  toggleHistoricalTraining,
  onNewTrainingClick,
  onEditTrainingClick,
  onDeleteTrainingClick,
  onDeleteCourseClick,
  onRemoveBooking,
  onAddBooking,
  onAddELearningBooking,
  onBookingReminder
}) {

  const currentActor = useCurrentActor();
  const trackedPersonnelContext = useTrackedPersonnelContext();
  const initialIsRequired = isRoleRequired || (!!userCourse && userCourse.attributes.required);

  const [isRequired, setIsRequired] = useState(!!(isRoleRequired ? isRequiredStatus : initialIsRequired));

  const manageUserCourse = () => {
    if (isRoleRequired) return
    if (!userCourse && isRequired) {
      createUserCourse({ course, params: { required: true } })
    } else if (userCourse && userCourse.attributes.required !== isRequired) {
      updateUserCourse({ userCourse, params: { required: isRequired } })
    }
  };

  const optionsHandler = (setOptionsOpen, callback) => {
    callback()
    setOptionsOpen(false)
  };

  const prioritisedDeletionStatus = (() => {
    switch (true) {
      case !!training: return 'deleteTraining'
      case (isRequiredByAnotherRole || isRoleRequired): return 'roleRequired'
      case !!userCourse: return 'deleteUserCourse'
      case !!booking: return 'courseIsBooked'
      default: return null
    }
  })()

  const prioritisedBookingStatus = (() => {
    switch (true) {
      case !booking && !trackedPersonnelContext.usage.loaded: return 'loadingTrackedPersonnelUsage';
      case !booking && !trackedPersonnelContext.isCurrentPersonnelTracked && trackedPersonnelContext.hasReachedLimit: return 'trackedPersonnelLimitReached';
      case courseHasELearningCourse && !!registration: return 'eLearningInProgress';
      case !!booking && courseHasELearningCourse: return 'removeBooking';
      case (courseHasELearningCourse && creditInfoUnavailable): return 'creditsInfoUnavailable';
      case (courseHasELearningCourse && !currentPersonnel.email): return 'unbookableELearning'
      case (courseHasELearningCourse): return 'bookElearning';
      case !booking: return 'addBooking';
      default: return null;
    }
  })()

  const deletionOption = {
    'deleteTraining': (
      <OptionChoice
        warning={true}
        onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onDeleteTrainingClick({ training, course }))}
      >
        <a className='tw-text-m tw-text-red-600 hover:tw-text-red-600 tw-font-medium tw-tracking-auto'>Delete existing training</a>
      </OptionChoice>
    ),
    'roleRequired': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip={`Course is required by ${isAdditional ? 'another ' : ''}role`}>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Delete course</a>
        </Tooltip>
      </OptionChoice>
    ),
    'courseIsBooked': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='Course is booked for training'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Delete course</a>
        </Tooltip>
      </OptionChoice>
    ),
    'deleteUserCourse': (
      <OptionChoice
        warning={true}
        onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onDeleteCourseClick({ userCourse, course }))}
      >
        <a className='tw-text-m tw-text-red-600 hover:tw-text-red-600 tw-font-medium tw-tracking-auto'>Delete course</a>
      </OptionChoice>
    )
  }[prioritisedDeletionStatus];

  const bookingOption = {
    'loadingTrackedPersonnelUsage': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='Checking usage...'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Add booking</a>
        </Tooltip>
      </OptionChoice>
    ),
    'trackedPersonnelLimitReached': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='You have reached your limit for tracked personnel'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Add booking</a>
        </Tooltip>
      </OptionChoice>
    ),
    'eLearningInProgress': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='eLearning course has been started'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Remove booking</a>
        </Tooltip>
      </OptionChoice>
    ),
    'removeBooking': (
      <OptionChoice onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onRemoveBooking(booking.id))}>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Remove booking</a>
      </OptionChoice>
    ),
    'creditsInfoUnavailable': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='There was a problem displaying your remaining credits'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Book eLearning</a>
        </Tooltip>
      </OptionChoice>
    ),
    'unbookableELearning': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='Personnel must have an email address to book eLearning'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Book eLearning</a>
        </Tooltip>
      </OptionChoice>
    ),
    'bookElearning': (
      <OptionChoice onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onAddELearningBooking({ course, eLearningCourse }))}>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Book eLearning</a>
      </OptionChoice>
    ),
    'addBooking': (
      <OptionChoice onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onAddBooking({ course }))}>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Add booking</a>
      </OptionChoice>
    )
  }[prioritisedBookingStatus];

  const historicalTrainingChoice = (
    <OptionChoice onClick={(setOptionsOpen) => {
      setOptionsOpen(false)
      if (!isHistoricalTrainingVisible) {
        sendAnalytics('Show training history clicked', { currentUser: currentActor.user, personnelId })
      }
      toggleHistoricalTraining()
    }}>
      <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>{`${isHistoricalTrainingVisible ? 'Hide' : 'Show'} training history`}</a>
    </OptionChoice>
  );

  const newTrainingStatus = (() => {
    switch (true) {
      case !!registration: return 'elearningStarted';
      case !trackedPersonnelContext.usage.loaded: return 'loadingTrackedPersonnelUsage';
      case !trackedPersonnelContext.isCurrentPersonnelTracked && trackedPersonnelContext.hasReachedLimit: return 'trackedPersonnelLimitReached';
      default: return 'default';
    }
  })()

  const newTrainingOptionChoice = {
    'loadingTrackedPersonnelUsage': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='Checking usage...'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Record new training</a>
        </Tooltip>
      </OptionChoice>
    ),
    'trackedPersonnelLimitReached': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='You have reached your limit for tracked personnel'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Record new training</a>
        </Tooltip>
      </OptionChoice>
    ),
    'elearningStarted': (
      <OptionChoice disabled={true}>
        <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='eLearning course has been started'>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Record new training</a>
        </Tooltip>
      </OptionChoice>
    ),
    'default': (
      <OptionChoice onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onNewTrainingClick({ course }))}>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Record new training</a>
      </OptionChoice>
    )
  }[newTrainingStatus];

  return (
    <>
      {showHistoricalTrainingOnly ? (
        <Options>
          {historicalTrainingChoice}
        </Options>
      ) : (
        <Options onClose={manageUserCourse}>
          {newTrainingOptionChoice}
          {training ? (
            <OptionChoice onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onEditTrainingClick({ training, course }))}>
              <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Edit existing training</a>
            </OptionChoice>
          ) : (
            <OptionChoice disabled={true}>
              <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='No training has been recorded'>
                <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Edit existing training</a>
              </Tooltip>
            </OptionChoice>
          )}
          {bookingOption}
          {!!booking && courseHasELearningCourse && (
            <OptionChoice disabled={!currentPersonnel.email} onClick={(setOptionsOpen) => optionsHandler(setOptionsOpen, () => onBookingReminder(booking.id))}>
              {!currentPersonnel.email ? (
                <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip='Personnel has no email'>
                  <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Send booking reminder</a>
                </Tooltip>
              ) : (
                <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Send booking reminder</a>
              )}
            </OptionChoice>
          )}
          {isRoleRequired ? (
            <OptionChoice hoverClassName='hover:tw-bg-blue-025' disabled={true}>
              <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip={isRequired ? 'Course is required by role' : 'Course is optional for role'}>
                <a className='tw-group/option tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>
                  <Switch className='fl-r' checked={isRequired} />
                  <span>Required?</span>
                </a>
              </Tooltip>
            </OptionChoice>
          ) : (
            <OptionChoice hoverClassName='hover:tw-bg-blue-025' onClick={(_setOptionsOpen) => { setIsRequired(!isRequired) }}>
              <a className='tw-group/option tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>
                <Switch className='fl-r' checked={isRequired} />
                <span>Required?</span>
              </a>
            </OptionChoice>
          )}
          {isMultipleTraining && (
            historicalTrainingChoice
          )}
          {deletionOption}
        </Options>
      )}
    </>
  )
}

TrainingOptions.propTypes = {
  showHistoricalTrainingOnly: PropTypes.bool,
  isRoleRequired: PropTypes.bool,
  isAdditional: PropTypes.bool,
  isMultipleTraining: PropTypes.bool,
  isHistoricalTrainingVisible: PropTypes.bool,
  courseHasELearningCourse: PropTypes.bool,
  course: PropTypes.object.isRequired,
  eLearningCourse: PropTypes.object,
  booking: PropTypes.object,
  registration: PropTypes.object,
  training: PropTypes.object,
  creditInfoUnavailable: PropTypes.bool,
  toggleHistoricalTraining: PropTypes.func,
  onNewTrainingClick: PropTypes.func.isRequired,
  onEditTrainingClick: PropTypes.func.isRequired,
  onDeleteTrainingClick: PropTypes.func.isRequired,
  onDeleteCourseClick: PropTypes.func.isRequired,
  onAddBooking: PropTypes.func.isRequired,
  onAddELearningBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired
}

TrainingOptions.defaultProps = {
  isAdditional: false
}
