import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';

import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import SelectDivision from 'components/teams/SelectDivision';
import SelectMembers from 'components/teams/SelectMembers';
import SelectedMembersList from "./SelectedMembersList";

export default function TeamsForm({
  team,
  teamMembers,
  onTeamNameInputChange,
  onTeamCompanyOptionChange,
  onTeamMemberSelect,
  onTeamMemberStatusChange,
  onTeamMemberDelete,
  requestError,
  removeErrorStyling,
  sidePanelFieldRef,
  sidePanelContext,
  isFetchingTeamMembers,
}) {
  const breadBoard = useBreadBoard();
  const [assignableCompanies, setAssignableCompanies] = useState({ loaded: false, collection: [] });
  const currentActor = useCurrentActor();

  function fetchAssignableCompanies() {
    axios
    .get('/companies')
    .then((response) => {
      setAssignableCompanies({ loaded: true, collection: response.data.data })
    })
    .catch(breadBoard.addInedibleToast)
  }

  useEffect(() => {
    fetchAssignableCompanies()
  }, [])

  return (
    <div className='popup__body-form'>
      <ErrorMessage validationErrors={requestError.validationErrors} isFallback={requestError.isFallback}/>
      <div className='form-container-section'>
        <TextField
          label='Team name'
          name='name'
          inputRef={sidePanelFieldRef}
          value={team.name}
          isRequired={true}
          fieldError={requestError.validationErrors.name}
          removeErrorStyling={removeErrorStyling}
          onChange={onTeamNameInputChange}
        />
        <SelectDivision
          team={team}
          sidePanelContext={sidePanelContext}
          assignableCompanies={assignableCompanies.collection}
          onTeamCompanyOptionChange={onTeamCompanyOptionChange}
        />
        {sidePanelContext === 'new' && currentActor.division.attributes.primary && (
          <span className="tw-text-s tw-text-grey-500">Changing the division after adding team members may result in those members being removed from the list below.</span>
        )}
      </div>
      <hr className='tw-h-px tw-bg-grey-100 tw-my-8 tw-border-0'/>
      <div className='form-container-section'>
        <SelectMembers
          team={team}
          onTeamMemberSelect={onTeamMemberSelect}
        />
      </div>
      <div className='popup__body-form p-0 m-b-32'>
        <div className='form-container'>
          {isFetchingTeamMembers ? (
            <span className='block-list__item tw-border-grey-100'>
              <div className='block-list__item--flex-container'>
                <div className='block-list__item--align-center'>
                  <span>Loading members...</span>
                </div>
              </div>
            </span>
          ) : (
            <SelectedMembersList
              domainTeamMembers={teamMembers}
              onChange={onTeamMemberStatusChange}
              onTeamMemberDelete={onTeamMemberDelete}
              team={team}
            />
          )}
        </div>
      </div>
    </div>
  )
}

TeamsForm.propTypes = {
  team: PropTypes.object.isRequired,
  onTeamNameInputChange: PropTypes.func.isRequired,
  onTeamCompanyOptionChange: PropTypes.func.isRequired,
  onTeamMemberSelect: PropTypes.func.isRequired,
  onTeamMemberStatusChange: PropTypes.func.isRequired,
  onTeamMemberDelete: PropTypes.func.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  sidePanelFieldRef: PropTypes.object.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  isFetchingTeamMembers: PropTypes.bool.isRequired
};
