import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/application/TextField';
import SelectedPersonnelCard from 'components/users/SelectedPersonnelCard';
import { validEmailRegex } from 'constants/regex';

UserDetailsEditor.propTypes = {
  formUser: PropTypes.object.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onSelectionReset: PropTypes.func.isRequired,
  onSelectionEdit: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired
}

export default function UserDetailsEditor ({ formUser, requestError, removeErrorStyling, onSelectionEdit, onSelectionReset, onDetailsChange }) {
  return (
    <>
      {formUser.isDerived && (
        <SelectedPersonnelCard
          onClose={() => onSelectionReset()}
          firstName={formUser.firstName}
          lastName={formUser.lastName}
          email={formUser.email}
          onEdit={onSelectionEdit}
        />
      )}
      {formUser.email && !formUser.isDerived && (
        <div className='tooltip-parent'>
          <TextField
            label='Email address'
            name='email'
            value={formUser.email}
            fieldError={requestError.validationErrors.email}
            placeholder={''}
            isDisabled={true}
            clearable={true}
            isRequired={true}
            onClear={() => onSelectionReset({ emailOnly: true })}
            onChange={() => void(0)}
            removeErrorStyling={removeErrorStyling}
          />
          {!validEmailRegex.test(formUser.email) && <div className={'m-b-12 tw-text-red-800 tw-text-s tw-tracking-wide'}>This is not a valid email address</div>}
        </div>
      )}
      {!formUser.isDerived && (
        <>
          <TextField
            label='First name'
            name='firstName'
            value={formUser.firstName}
            fieldError={requestError.validationErrors.firstName}
            removeErrorStyling={removeErrorStyling}
            isRequired={true}
            onChange={(e) => onDetailsChange({ attribute: 'firstName', value: e.target.value })}
          />
          <TextField
            label='Last name'
            name='lastName'
            value={formUser.lastName}
            fieldError={requestError.validationErrors.lastName}
            removeErrorStyling={removeErrorStyling}
            isRequired={true}
            onChange={(e) => onDetailsChange({ attribute: 'lastName', value: e.target.value })}
          />
        </>
      )}
      <TextField
        label='Position'
        name='user[position]'
        value={formUser.position || ''}
        onChange={(e) => onDetailsChange({ attribute: 'position', value: e.target.value })}
        placeholder={'e.g. Project owner'}
      />
      <div className={'tw-text-s tw-font-normal tw-tracking-wide m-t--6'}>The position is displayed at the top of any documents the user has created. It can be different from their role on the project.</div>
    </>
  )
}
