import HandshqApp from '../handshq-app';

import { handsColours, addHandsColours, addScrollingEvents } from 'helpers/tinymce';

import 'style-loader!tinymce/skins/handshq/skin.min.css';
import contentStyle from 'tinymce/skins/handshq/content.min.css';

HandshqApp.setupWysiwyg = function(options) {
  const toolbarOptions = `bold underline bullist${options['coloursAllowed'] ? ' forecolor' : ''} undo redo table`;

  tinyMCE.init({
    selector: options['selector'],
    resize: true,
    skin: false,
    branding: false,
    content_style: contentStyle.toString(),
    content_css: false,
    width: '100%',
    height: '292',
    body_class: 'tinyMCE-content',
    menubar: false,
    statusbar: true,
    elementpath: false,
    custom_colors: false,
    plugins: 'lists table tinymcespellchecker',
    spellchecker_language: 'en_uk',
    spellchecker_on_load: true,
    external_plugins: { 'powerpaste': '/powerpaste/plugin-5.4.0.js' },
    powerpaste_word_import: 'merge',
    powerpaste_html_import: 'merge',
    valid_elements: 'p,em,br,strong,li,table[*],colgroup[*],col[*],tbody[*],tr[*],th[*],td[*]',
    extended_valid_elements: 'span[class|style]',
    valid_styles: {
      'span': 'text-decoration,color',
      'table': 'border-collapse,width,height',
      'tr': 'height',
      'th': 'width,height,text-align,vertical-align',
      'td': 'width,height,text-align,vertical-align'
    },
    table_toolbar: 'tabledelete',
    toolbar: toolbarOptions,
    color_map: [handsColours.red.hex, 'Red', handsColours.green.hex, 'Green'],
    table_default_attributes: { border: '1', class: 'hands-default-table' },
    table_advtab: false,
    table_row_advtab: false,
    table_cell_advtab: false,
    setup: setupCallback,
    init_instance_callback: initInstanceCallback,
    valid_classes: { 'span': 'hands-red hands-green', 'table': 'hands-default-table' }
  })

  function setupCallback(editor) {
    options['setupCallback'](editor)
  }

  function initInstanceCallback(editor) {
    addHandsColours(editor)
    addScrollingEvents(editor)

    options['initInstanceCallback'](editor)
  }
};
