import { handsColours, addHandsColours } from 'helpers/tinymce';

import 'style-loader!tinymce/skins/handshq/skin.min.css';
import contentStyle from 'tinymce/skins/handshq/content.min.css';

$(function() {
  if ( $( '.nadmin-rich-text' ).length > 0 ){
    tinymceNadminInit($('.nadmin-rich-text'))
  } else if ($('.front-rich-text').length > 0 ){
    const editors = $('.front-rich-text');

    for(let i=0; i < editors.length; i++) { tinymceInit(editors[i]) }
  }
})

// Generic tinyMCE initializer
function tinymceInit(form) {
  tinyMCE.init({
    selector: '#' + form.id,
    resize: true,
    skin: false,
    branding: false,
    content_style: contentStyle.toString(),
    content_css: false,
    body_class: 'tinyMCE-content',
    menubar: false,
    statusbar: true,
    elementpath: false,
    plugins: 'lists table',
    external_plugins: { 'powerpaste': '/powerpaste/plugin-5.4.0.js' },
    powerpaste_word_import: 'merge',
    powerpaste_html_import: 'merge',
    toolbar: 'bold bullist removeformatting',
    table_toolbar: "tabledelete",
    valid_elements: 'p,em,br,strong,li,table[*],colgroup[*],col[*],tbody[*],tr[*],th[*],td[*]',
    extended_valid_elements: 'span[class]',
    init_instance_callback: function (editor) {
      editor.on('change Paste', function (_e) { editor.save() })
    },
    valid_classes: { 'span': 'hands-red hands-green', 'table': 'hands-default-table' }
  })
}

// Method text tinyMCE initializer with autosave functionality
function tinymceMethodTextInit(MethodText) {
  let toolbarOptions = 'bold bullist';

  if (MethodText.coloursAllowed){
    toolbarOptions += ' forecolor';
  }

  const formText = MethodText.textArea;
  const spinner = MethodText.spinner;
  const container = MethodText.container;
  let autosave;

  tinyMCE.init({
    selector: '#' + formText.id,
    resize: true,
    skin: false,
    branding: false,
    content_style: contentStyle.toString(),
    content_css: false,
    width: '100%',
    height: '245',
    body_class: 'tinyMCE-content',
    menubar: false,
    statusbar: true,
    elementpath: false,
    custom_colors: false,
    plugins: 'lists table',
    external_plugins: { 'powerpaste': '/powerpaste/plugin-5.4.0.js' },
    powerpaste_word_import: 'merge',
    powerpaste_html_import: 'merge',
    valid_elements: 'p,em,br,strong,li,table[*],colgroup[*],col[*],tbody[*],tr[*],th[*],td[*]',
    extended_valid_elements: 'span[class]',
    table_toolbar: 'tabledelete',
    toolbar: toolbarOptions,
    color_map: [handsColours.red.hex, 'Red', handsColours.green.hex, 'Green'],
    setup: function(editor) {
      // When the rich text loads then hide the spinner and show the text editor
      editor.on('init', function(_e) {
        spinner.fadeOut('fast', function() {
          container.fadeIn('fast')
        })
      })
    },
    init_instance_callback: function (editor) {
      addHandsColours(editor)

      editor.on('change Paste', function(_e) {
        editor.save()
        clearTimeout(autosave)
        autosave = setTimeout(function() {
          MethodText.submit()
        }, 5000);
      })
    },
    valid_classes: { 'span': 'hands-red hands-green', 'table': 'hands-default-table' }
  })
}


// Master method text tinyMCE initializer
function tinymceNadminInit(MasterMethodText) {
  tinyMCE.init({
    selector: '#' + MasterMethodText[0].id,
    skin: false,
    branding: false,
    content_style: contentStyle.toString(),
    content_css: false,
    body_class: 'tinyMCE-content',
    menubar: false,
    statusbar: false,
    custom_colors: false,
    plugins: 'lists table code',
    external_plugins: { 'powerpaste': '/powerpaste/plugin-5.4.0.js'},
    toolbar: 'bold bullist italic code forecolor table',
    color_map: [handsColours.red.hex, 'Red', handsColours.green.hex, 'Green'],
    table_default_attributes: { border: '1', class: 'hands-default-table' },
    valid_elements: 'p,em,br,strong,li,table[*],colgroup[*],col[*],tbody[*],tr[*],th[*],td[*]',
    extended_valid_elements: 'span[class]',
    powerpaste_word_import: 'merge',
    powerpaste_html_import: 'merge',
    init_instance_callback: addHandsColours,
    valid_classes: { 'span': 'hands-red hands-green', 'table': 'hands-default-table' }
  })
}

export { tinymceNadminInit, tinymceMethodTextInit };
