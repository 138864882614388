import React from 'react';
import PropTypes from 'prop-types';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import CourseSidePanelShow from 'components/courses/sidepanel/CourseSidePanelShow';
import CourseSidePanelForm from 'components/courses/sidepanel/CourseSidePanelForm';
import TrainingRegisterReportsSelector from 'components/trainingRegisterReports/TrainingRegisterReportsSelector';

export default function CourseSidePanel(props) {
  const {
    allowShowFooterContents,
    currentCourse,
    eLearningCourse,
    assignableRoles,
    isLoadMorePersonnelActive,
    personnel,
    requestError,
    submitDisabled,
    removeErrorStyling,
    isOpen,
    closeCallback,
    sidePanelContext,
    setSidePanelContext,
    updateCourse,
    createCourse,
    onInputChange,
    onOptionChange,
    onELearningProviderSelectedChange,
    onCourseCompanyRoleRequiredChange,
    onCourseCompanyRoleDelete,
    onCourseRolesOptionChange,
    loadMoreRef
  } = props;

  const openEditCourse = () => { setSidePanelContext('edit') };
  const bodyRef = React.useRef();
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const reportsTabViewable = currentActor.isAllowedFeature('training_register') && trainingRegisterResourceManagementContext.hasPersonnelViewableAccess;

  let heading = ''
  switch(sidePanelContext) {
    case 'edit':
      heading = 'Edit Course'
      break
    case 'new':
      heading = 'Add new course'
      break
    case 'show':
      heading = 'Course'
      break
  }

  const headingStyle = sidePanelContext === 'show' ? (
    'tw-text-s tw-text-grey-500 tw-font-medium tw-tracking-wide' ) : (
    'tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'
  )

  const headerContent =
    <>
      <div className='popup__title popup__title--tertiary'>
        <h1 className={`${headingStyle}`}>{heading}</h1>
      </div>
      {sidePanelContext === 'show' && (
        <div>
          <h2 className={`tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-700 tw-mt-2 ${reportsTabViewable ? 'tw-mb-4' : 'tw-mb-0' }`}>{currentCourse.name}</h2>
          {reportsTabViewable && (
            <div>
              <TrainingRegisterReportsSelector filter={{ courseId: { eq: currentCourse.id } }} />
            </div>
          )}
        </div>
      )}
      {sidePanelContext === 'edit' && trainingRegisterResourceManagementContext.isMultiDivisionAccount && (
        <p className='tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 m-0 m-t-20'>
          Changes could affect personnel training records related to this course. These might include personnel in divisions you do not have access to.
        </p>
      )}
    </>

  const bodyContent = sidePanelContext === 'show' ? (
    <CourseSidePanelShow
      currentCourse={currentCourse}
      eLearningCourse={eLearningCourse}
      assignableRoles={assignableRoles}
      personnel={personnel}
      isLoadMorePersonnelActive={isLoadMorePersonnelActive}
      sidePanelIsOpen={isOpen}
      loadMoreRef={loadMoreRef}
    />
  ) : (
    <CourseSidePanelForm
      currentCourse={currentCourse}
      eLearningCourse={eLearningCourse}
      requestError={requestError}
      removeErrorStyling={removeErrorStyling}
      onInputChange={onInputChange}
      onOptionChange={onOptionChange}
      onELearningProviderSelectedChange={onELearningProviderSelectedChange}
      assignableRoles={assignableRoles}
      bodyRef={bodyRef}
      onCourseCompanyRoleRequiredChange={onCourseCompanyRoleRequiredChange}
      onCourseCompanyRoleDelete={onCourseCompanyRoleDelete}
      onCourseRolesOptionChange={onCourseRolesOptionChange}
    />
  );

  const footerContent = () => {
    switch(sidePanelContext) {
      case 'new': return (
        <FormFooter
          text='Add new course'
          submitButtonDisabled={submitDisabled}
          onCancel={closeCallback}
          onSubmit={createCourse}
        />
      )
      case 'show': return (
        allowShowFooterContents &&
          <ShowFooter
            editType='course'
            resource={currentCourse.name}
            onEditClick={openEditCourse}
            onDeleteClick={props.onDeleteClick}
            deleteDisabled={currentCourse.trainingCount > 0}
            deleteDisabledTooltip='You cannot delete courses with recorded training, including training of personnel that were archived'
          />
      )
      case 'edit': return (
        <FormFooter
          text='Save changes'
          submitButtonDisabled={submitDisabled}
          onCancel={closeCallback}
          onSubmit={updateCourse}
        />
      )
    }
  };

  return (
    <SidePanel
      color={'tertiary'}
      isOpen={isOpen}
      displayClose={sidePanelContext === 'show'}
      closeCallback={closeCallback}
      scrollHeader={false}
      headerContent={headerContent}
      bodyContent={bodyContent}
      bodyRef={bodyRef}
      footerContent={footerContent()}
      contentContext={sidePanelContext}
      submitDisabled={submitDisabled}
    />
  )
}

CourseSidePanel.propTypes = {
  allowShowFooterContents: PropTypes.bool.isRequired,
  currentCourse: PropTypes.object.isRequired,
  eLearningCourse: PropTypes.object,
  requestError: PropTypes.object.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  createCourse: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onRequestError: PropTypes.func.isRequired,
  onELearningProviderSelectedChange: PropTypes.func.isRequired,
  assignableRoles: PropTypes.object.isRequired,
  isLoadMorePersonnelActive: PropTypes.bool.isRequired,
  personnel: PropTypes.object.isRequired,
  onCourseCompanyRoleRequiredChange: PropTypes.func.isRequired,
  onCourseCompanyRoleDelete: PropTypes.func.isRequired,
  onCourseRolesOptionChange: PropTypes.func.isRequired
}
