import React from 'react';

import Tooltip from 'components/application/Tooltip';
import SmallStubPill from 'components/application/pills/SmallStubPill';

export default function ELearningCourseInProgressPill() {
  return (
    <Tooltip placement='top-end' trigger='hover' tooltip='eLearning in progress'>
      <SmallStubPill className='app-text__x-small t--bold va-middle tw-bg-green-025 tw-text-green-800'>
        eLearning in progress
      </SmallStubPill>
    </Tooltip>
  )
}
